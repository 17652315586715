.site-nav {
    // Set alignment properties in parent component

    a {
        text-decoration: none;

        &[aria-current="page"] {
            color: var(--color__primary);
        }
    }
}
