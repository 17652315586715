:root {
    --text-block__spacing: 1rem;
    --text-block__large-spacing: 3rem;
    --text-block__p__font-size: 1.125rem;
    --text-block__image__caption__font-size: 0.625rem;

    @include MQ-Above(large) {
        --text-block__large-spacing: 4rem;
        --text-block__p__font-size: 1.25rem;
        --text-block__image__caption__font-size: 0.75rem;
    }
}

.text-block {
    // The article content is WYSIWYG object
    & > * + * {
        margin-top: var(--text-block__spacing);
    }

    figure + *,
    blockquote + * {
        margin-top: var(--text-block__large-spacing);
    }

    * + figure,
    * + blockquote {
        margin-top: var(--text-block__large-spacing);
    }

    p {
        font-size: var(--text-block__p__font-size);
        line-height: 1.65;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend .title;

        & + * {
            margin-top: 1rem;
        }
    }

    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
        margin-top: 2rem;
    }

    > h2 {
        @extend .title--secondary;
    }

    > h3 {
        @extend .title--tertiary;
    }

    > h4 {
        --title__color: var(--color__black--faded-50);
    }

    // Image and caption styles
    figure {
        img {
            margin: auto;
            width: 100%;
        }

        figcaption {
            font-family: var(--font-family__primary);
            font-size: var(--text-block__image__caption__font-size);
            font-weight: 400;
            text-align: right;
            padding: 0.5rem 0 0;
            opacity: 0.5;
        }
    }

    // Pullquote styles
    blockquote {
        --title__color: var(--color__deep-red);

        @extend .title;
        @extend .title--secondary;

        position: relative;

        @include MQ-Below(large) {
            padding-top: 1em; // Ems work better here
        }

        &::before {
            content: "“";
            position: absolute;
            top: 0;
            left: 0;
        }

        @include MQ-Above(large) {
            &::before {
                top: 0;
                left: -0.75em; // Ems work better here
            }
        }
    }
}
