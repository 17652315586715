$modal__transitions: 0.2s cubic-bezier(0, 0, 0.2, 1);

.modal {
    // Handle visibility states
    // The MicroModal library uses a class to determine hide/show status visually, but uses aria-hidden to communicate immediate visibility to the browser. Use those to trigger animations.
    display: none;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(3px);
    overflow-y: scroll;

    &.is-open {
        display: block;
    }

    // Core overlay styles
    &__overlay {
        @extend .util__fill;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__close {
        background: var(--color__primary);
        color: white;
        padding: 15px 20px;
        position: absolute;
        top: 2rem;
        right: 2rem;
        z-index: 1;
        transition: opacity 0.25s ease-in;

        &:hover {
            opacity: 0.8;
        }
    }

    &[aria-hidden="false"] .modal__overlay {
        animation: mmfadeIn $modal__transitions;
    }

    &[aria-hidden="false"] .modal__container {
        animation: mmslideIn $modal__transitions;
    }

    &[aria-hidden="true"] .modal__overlay {
        animation: mmfadeOut $modal__transitions;
    }

    &[aria-hidden="true"] .modal__container {
        animation: mmslideOut $modal__transitions;
    }

    &__container,
    &__overlay {
        will-change: transform;
    }

    &__container {
        position: relative;
    }

    /*
   * Individual Modal styling
   */

    &--video {
        .modal__container {
            width: var(--modal--video__width, 100%);

            video {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                max-height: 95vh;
                width: auto;
            }
        }

        @include MQ-Above(large) {
            --modal--video__width: 80%;
        }
    }

    &__image {
        picture,
        img {
            width: 100%;
            object-fit: cover;
        }
    }
}

/*
 * Shared keyframe definitions
 * Can extend these more broadly if they are needed elsewhere
 */

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-10%);
    }
}
