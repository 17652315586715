:root {
    --a__hover__opacity: 0.6;
}

a {
    text-decoration: none;
    transition: var(--transition);
    transition-property: opacity;
    display: inline-block;
}
