.callout {
    padding: 30px 0 60px 0;


    &__inner {
        @extend .container;
        @include Columns(2, $sm: 2, $lg: 4);
        @include Gap(2.5rem, $lg: 3.75rem);
    }

    &__tile {
        color: var(--color__blue);

        &__image {
            margin-bottom: 1rem;
            box-shadow: (-5px 5px 0px var(--color__orange));
            transition: box-shadow 0.15s ease-in;
            @include MQ-Below(medium) {
                aspect-ratio: 1/1;
            }

            img {
                width: 100%;
                height: auto;
                @include MQ-Below(medium) {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &__eyebrow {
            @extend .heading--6;
        }

        &__title {
            @extend .heading--4;
            letter-spacing: -1px;

            .link--arrow {
                position: relative;
                max-width: calc(100% - 24px);
                &::after {
                    position: absolute;
                    bottom: 8px;
                }
            }
        }
    }

    &__link {
        .callout__tile__title {
            @extend .heading--4;
            letter-spacing: 0;
            max-width: 94%;
        }

        &:focus-within {
            @extend %dashed-focus;
        }
    }
}

a.callout__link:hover {
    .callout__tile__image {
        box-shadow: (-10px 10px 0px var(--color__orange));
    }
    .link--arrow {
        text-decoration: underline;

        &:after {
            transform: translateX(5px);
        }

        span {
            transform: translateX(5px);
        }
    }
}

.hero + .callout {
    position: relative;
    margin-top: -3rem;
    @include MQ-Below(medium) {
        margin-top: -4rem;
    }
}

.callout__services {
    margin: 4rem 0;
    text-align: center;
    .callout__headline {
        @extend .heading--2;
        margin-bottom: 1.25rem;
        @include MQ-Below(medium) {
            text-align: left;
        }
    }
    .callout__tile {
        text-align: left;
    }

    .callout__inner {
        justify-content: center;
        grid-template-columns: repeat(3, 240px);
        @include MQ-Below(medium) {
            display: flex;
            flex-direction: column;
        }

        a {
            @include MQ-Below(medium) {
                display: grid;
                grid-template-columns: 80px 1fr;
                gap: 1.25rem;
            }
        }

        .callout__container {
            .callout__tile__eyebrow {
                @include MQ-Below(medium) {
                    font-size: .813rem;
                }
            }
            .callout__tile__title {
                @include MQ-Below(medium) {
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                }

                &.link--arrow {
                    position: relative;
                    max-width: calc(100% - 24px);
                    &::after {
                        position: absolute;
                        bottom: 6px;
                        @include MQ-Above(medium) {
                            border-top: 8px solid transparent;
                            border-bottom: 8px solid transparent;
                            border-left: 12px solid currentColor;
                        }
                        @include MQ-Below(medium) {
                            bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}
