.timeline {
    background: var(--color__light-orange);
    padding: var(--spacing-l) 0;
    @include MQ-Below(medium) {
        padding: var(--spacing-s) 0;
    }

    &__inner {
        @include Container(810px);
    }

    &__segment {
        display: grid;
        grid-template-columns: 71px 1fr;
        column-gap: 29px;
        margin: 1.063rem 0;
        @include MQ-Below(medium) {
            grid-template-columns: 42px 1fr;
            column-gap: 22px;
        }

        &:first-of-type {
            .timeline__year {
                &::after {
                    background: #177ABC;
                }
            }
        }

        &:last-of-type {
            .timeline__year {
                &::before {
                    content: none;
                }

                &::after {
                    background: #177ABC;
                }
            }
        }
    }

    &__year {
        position: relative;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        padding: 10px 14px 10px 0;
        @include MQ-Below(medium) {
            font-size: 0.813rem;
            line-height: 1.125rem;
        }

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            right: 0;
            top: 28px;
            bottom: auto;
            border: 1px solid #177ABC;
        }

        &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            inset: 12px -7px auto auto;
            background: #FFFFFF;
            border: 2px solid #177ABC;
            border-radius: 9999px;
        }
    }

    &__events {

    }

    &__event {
        position: relative;
        background: white;
        display: flex;
        gap: 10px;
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 0.625rem;
        margin-bottom: .25rem;
        @include MQ-Below(medium) {
            font-size: 0.875rem;
            line-height: 1.25rem;
            grid-template-columns: 1fr 64px;
        }

        &:first-of-type {
            &::before {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                inset: 12px -7px auto;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-right: 7px solid white;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &__text {
            flex: 1;
        }

        &__image {
            flex-basis: 99px;

            @include MQ-Below(medium) {
                flex-basis: 64px;
                img {
                    height: 64px;
                    object-fit: cover;
                }
            }
        }
    }

    &__link {
        border: 1px solid #177ABC;
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 6px;
        border-radius: 4px;
        font-size: 0.813rem;
        line-height: 1.125rem;
        padding: 6px;
        margin-top: 0.625rem;

        a {
            text-decoration: underline;
        }
    }
}
