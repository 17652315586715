.text {
    font-family: var(--font-primary);

    &--large {
        font-size: 1.375rem;
        line-height: 1.875rem;
        font-weight: 400;

        @include MQ-Above(large) {
            font-size: 1.5rem;
            line-height: 1.875rem;
        }
    }

    &--medium {
        font-size: 1.15rem;
        line-height: 1.475rem;
        font-weight: 400;

        @include MQ-Above(large) {
            font-size: 1.5rem;
            line-height: 1.875rem;
        }
    }

    &--regular {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;

        @include MQ-Above(large) {
            font-size: 1.125rem;
            line-height: 1.75rem;
        }
    }

    &--small {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;

        @include MQ-Above(large) {
            font-size: 1rem;
            line-height: 1.75rem;
        }
    }
}
