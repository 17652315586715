:root {
    @include MQ-Above(large) {
        --button-container__direction: row;
        --button-container__align-items: center;
        --button-container__button-margin: 0 0 0 1.25rem;
    }
}

.button-container {
    margin: 1.25rem 0 0 0;
    display: flex;
    align-items: var(--button-container__align-items, auto);
    flex-direction: var(--button-container__direction, column);
    justify-content: flex-start;

    a + a {
        margin: var(--button-container__button-margin, 1.25rem 0 0 0);
    }

    &--centered {
        align-items: center;
        justify-content: center;
    }
}
