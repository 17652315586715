html {
    font-size: 16px; // This establishes what 1rem is
}

html,
body {
    height: 100%;
    min-height: 100vh;
    @include MQ-Below(medium) {
        height: auto;
    }
}

body {
    font-family: var(--font-family__primary);
    font-weight: 400;
    background: white;
    color: var(--color__blue);
    position: relative;

    // Disable scrolling when mobile nav or modal is open
    &[data-site-mobile-nav-active="true"],
    &[data-modal-active="true"] {
        height: 100%;
        overflow: hidden;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

main {
    flex: 1 0;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

/** This is bad practice, leaving here to say DONT DO THIS. **/
/** aria hidden means hide from screen readers, not hide from DOM **/
// [aria-hidden="true"] {
//     display: none;
// }

.skip-to-main {
    background: #3849F2;
    color: white;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 100;
    padding: 0.625rem;
    transform: var(--skip-to-main__transform, translateX(-1000px));
}

.skip-to-main:focus,
.skip-to-main:active {
    --skip-to-main__transform: none;
}

%dashed-focus {
    outline: 2px dashed #3849F2;
    outline-offset: 3px;
}
