/* SCSS Variables */

$tokens__max-width: 1350px;

:root {
    /* Primary Colors */
    --color__primary: #6875f5;
    --color__secondary: #00265b;

    --color__blue: #01416e;
    --color__dark-blue: #012940;
    --color__bright-blue: #177abc;
    --color__light-blue: #cbe0f0;
    --color__orange: #fb9b50;
    --color__light-orange: #fed1b0;
    --color__bright-yellow: #ffdf87;

    /* Utility Colors */
    --color__light-grey: #f5f5f5;
    --color__medium-grey: #e1e1e1;
    --color__dark-grey: #606060;
    --color__white--faded-50: fade(white, 50%);
    --color__black--faded-50: fade(black, 50%);

    /* Font Families */
    --font-family__primary: "Work Sans", sans-serif;
    --font-family__secondary: "Lato", sans-serif;

    /* Transition */
    --transition: all 0.5s;

    /* Sizing */
    --max-width: 1350px;

    /* Shadows */
    --box-shadow: 0 4px 15px rgba(172, 172, 172, 0.15);

    /* Spacing */
    --spacing-s: 0.9375rem;
    --spacing: 1.875rem;
    --spacing-l: 3.75rem;
    --spacing-xl: 7.5rem;

    @include MQ-Above(large) {
        --spacing-s: 1.875rem;
        --spacing: 3.75rem;
        --spacing-l: 5rem;
        --spacing-xl: 10rem;
    }
}
