.grid {
    display: grid;
    grid-gap: 1rem;

    @include MQ-Above(medium) {
        grid-gap: 1.875rem;
    }

    &--1 {
        grid-template-columns: 1fr;
    }

    &--2 {
        grid-template-columns: repeat(2, 1fr);
    }

    &--3 {
        grid-template-columns: repeat(3, 1fr);
    }

    &--4 {
        grid-template-columns: repeat(4, 1fr);
    }

    &--5 {
        grid-template-columns: repeat(5, 1fr);
    }

    &--6 {
        grid-template-columns: repeat(6, 1fr);
    }
}

.md\:grid {
    @include MQ-Above(medium) {
        &--1 {
            grid-template-columns: 1fr;
        }

        &--2 {
            grid-template-columns: repeat(2, 1fr);
        }

        &--3 {
            grid-template-columns: repeat(3, 1fr);
        }

        &--4 {
            grid-template-columns: repeat(4, 1fr);
        }

        &--5 {
            grid-template-columns: repeat(5, 1fr);
        }

        &--6 {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}

.lg\:grid {
    @include MQ-Above(large) {
        &--1 {
            grid-template-columns: 1fr;
        }

        &--2 {
            grid-template-columns: repeat(2, 1fr);
        }

        &--3 {
            grid-template-columns: repeat(3, 1fr);
        }

        &--4 {
            grid-template-columns: repeat(4, 1fr);
        }

        &--5 {
            grid-template-columns: repeat(5, 1fr);
        }

        &--6 {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}

.xl\:grid {
    @include MQ-Above(x-large) {
        &--1 {
            grid-template-columns: 1fr;
        }

        &--2 {
            grid-template-columns: repeat(2, 1fr);
        }

        &--3 {
            grid-template-columns: repeat(3, 1fr);
        }

        &--4 {
            grid-template-columns: repeat(4, 1fr);
        }

        &--5 {
            grid-template-columns: repeat(5, 1fr);
        }

        &--6 {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}
