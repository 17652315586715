.cta {
    transition: opacity 0.25s ease-in;

    &:hover {
        opacity: 0.8;
    }

    &--primary {
        background: var(--color__primary);
        color: white;
        padding: 15px 20px;
        border-radius: 5px;
    }

    &--secondary {
        display: flex;
        align-items: center;
        font-weight: 500;

        &:hover {
            text-decoration: underline;

            &:after {
                transform: translateX(5px);
            }
        }

        &:after {
            content: "";
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 10px solid currentColor;
            display: inline-block;
            margin-left: 8px;
            transition: transform 0.15s ease-in;
        }
    }
}
