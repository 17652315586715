@use "sass:color";

.fui-row {
    display: flex;
    align-items: center;
    margin: 1.5rem -.5rem;
    @include MQ-Below(medium) {
        gap: 1.5rem;
        flex-wrap: wrap;
    }

    .fui-field  {
        flex: 1;
        margin: 0 0.5rem;
        &.fui-hidden {
            display:none;
        }
        &[data-conditionally-hidden] {
            display: none;
        }
        @include MQ-Below(medium) {
            // margin: 0 1rem;
            flex-basis: 100%;
        }
    }
}

.fui-error-message {
    color: red;
    margin-top: 0.5rem;
}

.fui-label {
    font-weight: 500;
}

.fui-legend {
    font-weight: 500;
    display: block;
    margin-bottom: .75rem;
}

.fui-group > .fui-fieldset {
    legend.fui-legend {
        display:none;
    }
}

.fui-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.fui-required {
    color: var(--color__secondary);
}

.fui-field {
    label {
        display: block;
        margin-bottom: .5rem;

        span {
            color: #000;
            font-size: .85rem;
        }
    }

    fieldset {
        border: none;
        margin-inline: 0;
        padding-inline: 0;
        padding-block: 0;
        min-inline-size: 0;
    }

    input, textarea {
        background-color: rgba(23, 122, 188, 0.1);
        border: 3px solid var(--color__secondary);
        padding: .65rem;
        width: 100%;

        &:focus-within {
            @extend %dashed-focus;
        }
    }

    select {
        background-color: rgba(23, 122, 188, 0.1);
        border: 3px solid var(--color__secondary) !important;
        color: var(--color__blue);
        padding: .65rem;
        width: 100%;

        &:focus-within {
            @extend %dashed-focus;
        }
    }

    .fui-checkbox-input {
        background-color: transparent;
        border: 3px solid var(--color__secondary);
        width: 29px;
        height: 29px;
        margin: 0;
        @include MQ-Below(medium) {
            height: 24px;
            width: 24px;
        }
    }

    .fui-checkbox-label {
        font-weight: 500;
        margin-top: 0.5rem;
        margin-left: 0.75rem;
    }
}

.fui-submit {
    @extend .cta--secondary;
    background: var(--color__orange);
    color: var(--color__blue);
    display: inline-block;
    font-size: 1rem;
    text-align: center;
    padding: 0.75rem 1.875rem;
    font-weight: 600;
    border-radius: 6px;
    box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.15s ease-in, opacity 0.15s ease-in, background 0.15s ease-in;
    width: 100%;

    &:hover {
        background: color.scale(#fb9b50, $lightness: -5%);
    }

    &:focus-within {
        @extend %dashed-focus;
    }
}

.form-info {
    background: var(--color__blue);
    border-radius: 5px;
    color: white;
    font-size: .95rem;
    padding: 0.5rem 0.65rem;
    margin-bottom: 0;

    a {
        text-decoration: underline;
        &:focus-within {
            @extend %dashed-focus;
            outline-color: white;
        }
    }
}

.form-note{
    margin-top: 2.2rem;
    font-style: italic;
}

.fui-alert {
    background: var(--color__blue);
    border-radius: 5px;
    color: white;
    font-size: .95rem;
    padding: 0.65rem 0.65rem;
    margin-top: 1rem;

    a {
        text-decoration: underline;
    }
    &.fui-alert-error {
        background: none;
        color: red;
    }
}

.fui-heading-h2 {
    @include MQ-Below(medium) {
        font-size: 1.375rem;
        margin-bottom: -1.5rem;
    }
}

.fui-heading-h3 {
    @include MQ-Below(medium) {
        font-size: 1.25rem;
    }
}

.fui-row.fui-page-row.fui-row-empty {
    margin: 0;
}

.form-group__outlined {
    border: 4px solid var(--color__secondary);
    padding: 0 1.25rem;
}

.contact-form #membershipForm {
    h2 {
        @include MQ-Below(medium) {
            font-size: 1.75rem;
            line-height: 2.125rem;
            font-weight: 700;
        }
    }

    h3 {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;
    }

    .fui-heading-h3 {
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 600;
    }
}
