.site-footer {
    background: black;
    color: white;
    flex-shrink: 0;

    &__inner {
        @include Grid(3fr 2fr 2fr);
        @include Container();
    }

    &__copyright {
        @extend .container;
        @extend .title, .title--meta;

        text-align: center;
        color: var(--color__medium-grey);
    }

    p,
    a {
        color: white;
    }

    a {
        &:hover,
        &[aria-current="page"] {
            text-decoration: underline;
        }
    }

    header {
        @extend .title;
        @extend .title--quaternary;

        margin-bottom: 1rem;
    }

    nav ul {
        display: grid;
        grid-gap: 0.625rem;
    }

    &__about {
        p {
            max-width: 21rem;
        }

        &__logo {
            max-width: 10rem;
            margin-bottom: 1rem;
        }
    }

    &__social {
        display: flex;
        align-items: center;

        a {
            padding: 0.3125rem;
        }
    }
}
