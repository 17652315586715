.t__locations {

    .locations-map {
        position: relative;
        margin-top: 3rem;
        @include MQ-Below(medium) {
            margin-top: 0;
        }

        h2 {
            font-size: 1.75rem;
            font-weight: 700;
            margin-bottom: 1.25rem;
            @include MQ-Below(medium) {
                font-size: 1.75rem;
            }
        }

        .container {
            position: relative;
            margin-top: calc(-486px - 1.5rem);
            margin-bottom: 5.99rem;
            @include MQ-Below(medium) {
                margin-top: 4rem;
                margin-bottom: 1rem;
            }
        }

        &__map {
            img {
                height: 486px;
                object-fit: cover;
            }
        }

        &__stats {
            background: var(--color__orange);
            // position: absolute;
            // top: -1.5rem;
            // left: 3.25rem;
            // right: auto;
            width: 320px;
            padding: 1.25rem;
            text-align: center;
            box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.2);
            @include MQ-Below(medium) {
                position: relative;
                width: 100%;
                max-width: 400px;
                inset: initial;
                margin: 0 auto;
                margin-top: -8rem;
                margin-bottom: 1.875rem;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }

            .stat {
                margin-bottom: 2rem;
                &:last-of-type {
                    margin-bottom: 1rem;
                }

                img {
                    width: 28px;
                    height: 28px;
                }

                span {
                    font-size: 2.5rem;
                    font-weight: 600;
                    line-height: 0;
                }

                p {
                    font-size: 1rem;
                    font-weight: 500;
                }

                &__group {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.625rem;
                }
            }
        }
    }

    .admin-buildings {
        h3 {
            margin-top: 0;
        }
    }

    .wysiwyg-text {
        p {
            color: var(--color__blue);
        }
    }
}
