:root {
    @include MQ-Above(large) {
        --card__padding: 2rem;
    }
}

.cards {
    background: var(--color__light-grey);

    &__inner {
        @include Container();
    }

    &__list {
        @include Grid(3, $repeat: true);

        margin-top: 1rem;

        &__card {
            background: white;
            padding: var(--card__padding, 1rem);

            h3 {
                @extend .title, .title--tertiary;

                margin-bottom: 0.625rem;
            }

            &__media {
                margin-bottom: 1rem;

                svg {
                    width: auto;
                }
            }
        }
    }
}
