:root {
    --title__font-family: var(--font-family__primary);
    --title__font-size: 1.8rem;
    --title__font-weight: 800;
    --title__line-height: 1.1;
    --title__color: "currentColor";
}

.title {
    font-family: var(--title__font-family);
    font-size: var(--title__font-size);
    font-weight: var(--title__font-weight);
    line-height: var(--title__line-height);
    color: var(--title__color);
}

/*
 * Extensions
 */
.title--primary {
    --title__font-size: 2.38rem;
    --title__line-height: 1;

    @include MQ-Above(large) {
        --title__font-size: 2.8rem;
    }
}

.title--secondary {
    --title__font-size: 1.75rem;

    @include MQ-Above(large) {
        --title__font-size: 2.19rem;
    }
}

.title--tertiary {
    --title__font-size: 1.25rem;

    @include MQ-Above(large) {
        --title__font-size: 1.56rem;
    }
}

.title--quaternary {
    --title__font-size: 1.1rem;

    @include MQ-Above(large) {
        --title__font-size: 1.25rem;
    }
}

.title--meta {
    --title__font-size: 0.75rem;

    @include MQ-Above(large) {
        --title__font-size: 0.88rem;
    }
}

.title--micro {
    --title__font-size: 0.62rem;

    @include MQ-Above(large) {
        --title__font-size: 0.75rem;
    }
}

/*
 * Weight Adjustments
 */

.title--font-weight-light {
    --title__font-weight: 300;
}

.title--font-weight-normal {
    --title__font-weight: 400;
}

.title--font-weight-semi-bold {
    --title__font-weight: 500;
}

/*
 * Size Adjustments
 */

.title--font-size-medium {
    --title__font-size: 1.5rem;
}

.title--font-size-small {
    --title__font-size: 1.1rem;
}

.title--font-size-smaller {
    --title__font-size: 0.8rem;
}

/*
 * Appearance Adjustments
 */

.title--uppercase {
    text-transform: uppercase;
}
