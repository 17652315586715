:root {
    @include MQ-Below(medium) {
        --image-with-quote--small__padding: 30px 20px 20px 30px;
    }

    @include MQ-Above(medium) {
        --image-with-quote--xlarge__height: 55%;
        --image-with-quote--large__height: 35%;
        --image-with-quote--large__text-margin: -100px auto 0 0;
    }
}

.image-with-quote {
    &--xlarge {
        position: relative;

        .image-with-quote__media {
            width: 100%;
            height: auto;
            position: relative;
            padding-top: var(--image-with-quote--xlarge__height, 140%);

            picture {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                overflow: hidden;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        .image-with-quote__inner {
            @include Container();
            @include Gap();
            margin-bottom: 4rem;

            @include MQ-Above(large) {
                position: absolute;
                bottom: 4rem;
                left: 4rem;
                margin-bottom: 0;
            }
        }

        .image-with-quote__text {
            background: var(--color__bright-blue);
            color: white;
            padding: 30px;
            position: relative;
            z-index: 1;
            display: block;
            text-align: center;
            width: 25rem;
            max-width: calc(100% - 30px);
            margin: var(
                --image-with-quote--large__text-margin,
                -100px auto 0 auto
            );

            blockquote {
                @extend .heading--3;
            }

            figcaption {
                @extend .heading--6;
                margin-top: 1.25rem;
            }
        }
    }
    &--large {
        margin-bottom: 4rem;
        @include MQ-Below(medium) {
            margin-bottom: 3rem;
        }
        .image-with-quote__media {
            width: 100%;
            height: auto;
            position: relative;
            padding-top: var(--image-with-quote--large__height, 140%);

            picture {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                overflow: hidden;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        .image-with-quote__inner {
            @include Container();
            @include Gap();
        }

        .image-with-quote__text {
            background: var(--color__bright-blue);
            color: white;
            padding: 30px;
            position: relative;
            z-index: 1;
            display: block;
            text-align: center;
            width: 25rem;
            max-width: calc(100% - 30px);
            margin: var(
                --image-with-quote--large__text-margin,
                -100px auto 0 auto
            );
            @include MQ-Below(medium) {
                max-width: 100%;
            }

            blockquote {
                @extend .heading--3;
            }

            figcaption {
                @extend .heading--6;
                margin-top: 1.25rem;
            }
        }
    }

    &--small {
        padding: var(--image-with-quote--small__padding);

        .image-with-quote__inner {
            @include Grid(4fr 8fr, $sm: 5fr 7fr, $md_gap: 8.875rem, $sm_gap: 1rem);
            align-items: center;
            background: var(--color__bright-blue);
        }

        .image-with-quote__media {
            position: relative;
            max-width: 450px;
            z-index: 0;

            &::before {
                content: "";
                background: url(/assets/img/inclusivity_shape.svg) no-repeat center center;
                height: calc(100% + 5rem);
                width: 339px;
                position: absolute;
                top: -5rem;
                right: -12rem;
                z-index: -1;
                @include MQ-Below(large) {
                    width: 309px;
                    right: -10rem;
                }
            }
        }

        @include MQ-Below(medium) {
            margin-top: -1px;

            .image-with-quote__inner {
                padding: 0 20px 0 0;
                position: relative;
                align-items: flex-start;
            }

            .image-with-quote__media {
                width: 100%;
                height: auto;
                position: relative;
                padding-top: var(--image-with-quote--large__height, 140%);
                z-index: 1;

                &::before {
                    content: "";
                    background-size: contain;
                    height: 180px;
                    width: auto;
                    top: -4.4rem;
                    left: -15rem;
                }

                picture {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: -15px;
                    left: -15px;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        height: 100%;
                    }
                }
            }
        }

        .image-with-quote__text {
            color: white;
            padding: var(--spacing-s);
            position: relative;
            text-align: left;
            align-self: center;
            @include MQ-Below(large) {
                padding-left: 7rem
            }
            @include MQ-Below(medium) {
                padding: var(--spacing-s) 0;
            }

            blockquote {
                @extend .heading--3;
                @include MQ-Below(medium) {
                    font-size: 1rem;
                    line-height: 1.25rem;
                }
            }

            figcaption {
                @extend .heading--5;
                font-weight: 400;
                margin-top: 1.25rem;
                @include MQ-Below(medium) {
                    font-size: 0.813rem;
                }
            }
        }
    }

    &--no-quote {
        @include MQ-Below(large) {
            .image-with-quote__media {
                padding-top: 75%;
            }
        }
    }

    &--lightBlue {
        background: var(--color__light-blue);
    }
}
