@use "sass:color";

:root {
    @include MQ-Above(large) {
        --nav-desktop__display: inline-flex;
    }
}

.nav-desktop {
    // display: var(--nav-desktop__display, none);
    display: inline-flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: var(--color__blue);
    font-weight: 500;
    @include MQ-Below(navigation) {
        display: none;
    }

    > ul {
        display: flex;
        align-items: center;

        > li + li {
            margin-left: 10px;
        }
    }

    &__link {
        padding: 16px 10px;
        display: flex;
        align-items: center;
        font-size: clamp-sizes(.7, 1);

        &:hover {
            background: var(--color__orange);
        }

        &:focus-within {
            @extend %dashed-focus;
        }

        &--featured {
            @extend .cta--secondary;
            background: var(--color__orange);
            border-radius: 5px;
            font-weight: 600;
            padding: 16px 20px;
            box-shadow: -1px 2px 6px rgb(0 0 0 / 20%);
            transition: box-shadow 0.15s ease-in, opacity 0.15s ease-in, background 0.15s ease-in;

            &:hover {
                background: color.scale(#fb9b50, $lightness: -5%);
                text-decoration: none;
            }
        }
    }

    &__button {
        padding-right: 10px;
        margin-left: 7px;

        &:focus-within {
            @extend %dashed-focus;
        }

        &[aria-expanded="true"] {
            ~ .nav-desktop__dropdown__menu {
                visibility: visible;
            }

            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            min-width: 11px;
            transition: transform 0.15s ease-in;
        }
    }

    &__dropdown {
        display: flex;
        align-items: center;
        position: relative;

        .nav-desktop__link {
            padding: 16px 0 16px 10px;
            width: 100%;
        }

        &--active {
            > .nav-desktop__link {
                font-weight: 700;
            }
        }

        &__menu {
            display: block;
            visibility: hidden;
            position: absolute;
            top: 100%;
            left: 0;
            background: var(--color__orange);
            width: auto;
            min-width: 100%;
            font-weight: 400;

            li {
                &:hover {
                    font-weight: 500;
                }

                .nav-desktop__link {
                    padding: 16px 10px;
                }
            }

            .nav-desktop__dropdown--active {
                .nav-desktop__link {
                    text-decoration: underline;
                }
            }

        }

        &:hover, &:focus-within {
            background: var(--color__orange);

            svg {
                transform: rotate(180deg);
            }

            .nav-desktop__dropdown__menu {
                visibility: visible;
            }
        }

        &:focus-within {
            background: var(--color__orange);
        }
    }
}
