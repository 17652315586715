/* stylelint ignore */
//
//  RESTRICT LINES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include Restrict-Lines(3) {}
// Clever method for restricting line length, from https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css
@mixin Restrict-Lines($LineCount) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $LineCount; // Number of lines to show
    align-self: flex-start; // Fixes ellipsis when used inside a flex container (otherwise it gains 100% height)
}

@mixin Grid($md, $sm: 1fr, $md_gap: 1.875rem, $sm_gap: 1rem, $repeat: false) {
    display: grid;
    grid-gap: $sm_gap;
    grid-template-columns: $sm;

    @include MQ-Above(large) {
        grid-gap: $md_gap;
        /* stylelint-disable */
        @if $repeat {
            grid-template-columns: repeat($md, 1fr);
        } @else {
            grid-template-columns: $md;
        }
        /* stylelint-enable */
    }
}

// Used to create even columns
@mixin Columns($md, $lg: $md, $xl: $md, $sm: 1fr) {
    display: grid;
    grid-template-columns: repeat($sm, 1fr);

    @include MQ-Above(medium) {
        grid-template-columns: repeat($md, 1fr);
    }

    @if $lg != $md {
        @include MQ-Above(large) {
            grid-template-columns: repeat($lg, 1fr);
        }
    }

    @if $xl != $md {
        @include MQ-Above(x-large) {
            grid-template-columns: repeat($xl, 1fr);
        }
    }
}

// Used to create gap for columns & grid
@mixin Gap($md: 3.75rem, $lg: $md, $xl: $md, $sm: 1.875rem) {
    grid-gap: $sm;

    @include MQ-Above(medium) {
        grid-gap: $md;
    }

    @if $lg != $md {
        @include MQ-Above(large) {
            grid-gap: $lg;
        }
    }

    @if $xl != $md {
        @include MQ-Above(x-large) {
            grid-gap: $xl;
        }
    }
}

@mixin Container(
    $width: $tokens__max-width,
    $padding_desktop: 7.5rem,
    $padding_mobile: 3.75rem
) {
    width: calc(100% - #{$padding_mobile});
    max-width: $width;
    margin: 0 auto;

    @include MQ-Above(large) {
        width: calc(100% - #{$padding_desktop});
    }
}

@mixin DottedBorder($position: top) {
    content: "";
    height: 3px;
    width: 100%;
    background: url("/assets/img/dot.svg") repeat;
    position: absolute;
    @if $position == bottom {
        bottom: 0;
    } @else if $position == top {
        top: 0;
    }
}
