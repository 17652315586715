.people {
    h2 {
        @extend .heading--2;
        text-align: center;
        margin-bottom: var(--spacing-l);
        font-weight: 700;
        @include MQ-Below(large) {
            font-size: 2rem;
            text-align: left;
            margin: 2rem 0 1rem 0;
        }
    }

    h3 {
        @extend .heading--4;
        margin-bottom: .5rem;
    }

    &__inner {
        @extend .container;
        @include Gap(2.5rem, $lg: 2.75rem);
    }

    &__tiles {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3rem;
        justify-content: center;
        @include MQ-Below(large) {
            gap: 0;
        }

        > * {
            flex-basis: calc((100% / 3) - 3rem);
            display: grid;
            grid-template-columns: 100px 2fr;
            gap: 1rem;
            @include MQ-Below(large) {
                flex-basis: 100%;
                padding: 3rem 0;
                border-bottom: 4px dotted var(--color__orange);
            }
        }

        &-image {
            border-radius: 9999px;
            aspect-ratio: 1/1;
            overflow: hidden;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        &-title {
            line-height: 1.2;
            font-weight: 500;
        }

        &-contact {
            background: var(--color__light-blue);
            border-radius: 2px;
            padding: 0.625rem;

            a {
                display: flex;
                flex-direction: row;
                font-size: .75rem;
                gap: .65rem;
                margin-bottom: .5rem;

                &:last-of-type {
                    margin-bottom: 0;
                }

                > svg:nth-of-type(1n) {
                    fill: #01416E;
                    margin-top: 1px;
                    min-width: 16px;
                }
            }

            .link--arrow {
                span svg {
                    height: 10px;
                    width: 10px;
                    min-width: 10px;
                }

                &::after {
                    content: none;
                }
            }
        }
    }

    &--our-values {
        background: var(--color__light-orange);
        padding: var(--spacing-l) 0;
        @include MQ-Below(large) {
            padding: var(--spacing-s) 0;
        }

        .people__tiles {
            > * {
                &:last-of-type {
                    border: none;
                }
            }
        }
    }

    &--our-leadership {
        padding: var(--spacing-l) 0;
        @include MQ-Below(large) {
            padding: var(--spacing-s) 0;
            border-bottom: 2px solid var(--color__bright-blue);
            overflow: hidden;
        }

        .people__inner {
            @include MQ-Below(large) {
                margin-bottom: -1.2rem;
            }
        }

        .people__tiles {
            gap: 2rem;
            @include MQ-Below(large) {
                gap: 0;
            }
            > * {
                flex-basis: calc((100% / 3) - 2rem);
                grid-template-columns: 80px 2fr;
                align-items: center;
                height: fit-content;
                @include MQ-Below(large) {
                    flex-basis: 100%;
                }
            }

            &-image {
                align-self: flex-start;
            }
        }

        h2 {
            font-weight: 600;
            @include MQ-Below(large) {
                font-weight: 700;
                margin: 1rem 0 0 0;
            }
        }

        h3 {
            @extend .heading--5;
            font-weight: 600;
            margin-bottom: 0;
            @include MQ-Below(large) {
                font-size: 1.25rem;
            }
        }
    }

    &--board-directors {
        padding: var(--spacing-l) 0;
        @include MQ-Below(large) {
            padding: var(--spacing) 0 var(--spacing-s) 0;
        }

        h2 {
            text-align: left;
            font-weight: 600;
            @include MQ-Below(large) {
                font-weight: 700;
                margin: 1rem 0 0 0;
            }
        }

        h3 {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 0;
        }

        .people__tiles {
            gap: 2rem;
            @include MQ-Below(large) {
                gap: 0.3rem;
            }

            > * {
                flex-basis: calc((100% / 4) - 2rem);
                display: flex;
                margin-bottom: 0;
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 1rem;
                @include MQ-Below(large) {
                    flex-basis: calc((100% / 4) - 1rem);
                }
                @include MQ-Below(medium) {
                    flex-basis: calc((100% / 2) - 1rem);
                }
            }
            &-image {
                max-width: 80px;
            }
        }
    }

    &--board-officers {
        padding: var(--spacing-l) 0;
        @include MQ-Below(large) {
            padding: var(--spacing-s) 0;
            overflow: hidden;
        }

        h2 {
            font-size: 2.1rem;
            text-align: left;
            font-weight: 600;
            @include MQ-Below(large) {
                font-size: 1.7rem;
                font-weight: 700;
                margin: 1rem 0 0 0;
            }
        }

        h3 {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 0;
        }

        .people__tiles {
            gap: 1rem;
            @include MQ-Below(large) {
                gap: 0.3rem;
                margin-bottom: -1.2rem;
            }

            > * {
                flex-basis: calc((100% / 6) - 1rem);
                margin-bottom: 2rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 1rem;
                @include MQ-Below(large) {
                    flex-basis: calc((100% / 3) - 1rem);
                    margin-bottom: 0;
                }
                @include MQ-Below(medium) {
                    flex-basis: calc((100% / 2) - 1rem);
                }
            }
            &-image {
                max-width: 80px;
            }
        }
    }

    hr {
        height: 3px;
        width: 100%;
        background: url(/assets/img/dot.svg) repeat;
        border: none;
        margin: 3rem 0;

        &:last-of-type {
            display: none;
        }

        @include MQ-Below(large) {
            display: none;
        }
    }
}
