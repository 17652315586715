:root {
    --link-list__gutter: 1.25rem;
    --link-list__gutter__top: calc(0.5 * var(--link-list__gutter));
    --link-list__display: flex;

    @include MQ-Above(large) {
        --link-list__gutter: 1rem;
    }
}

ul.link-list {
    display: var(--link-list__display);
    flex-wrap: wrap;
    justify-content: var(--link-list__justify-content);
    margin: calc(-1 * var(--link-list__gutter__top)) 0 0
        calc(-1 * var(--link-list__gutter)); // Vertical gutters are smaller than horizontal

    li {
        display: flex;
        padding: var(--link-list__gutter__top) 0 0 var(--link-list__gutter);
    }
}
