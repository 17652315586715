:root {
    @include MQ-Above(medium) {
        --text-columns__image__width: 85%;
    }
}

.text-columns {
    background: var(--color__light-blue);
    padding: 60px 0;

    &__inner {
        @include Container();
        @include Columns(2);
        gap: 10rem;
        @include MQ-Below(large) {
            gap: 4rem;
         }

        .text-columns__col + .text-columns__col {
            position: relative;
            &::before {
                content: '';
                width: 6px;
                height: 100%;
                background: url("/assets/img/dotted_line.svg") repeat;
                position: absolute;
                top: 0;
                bottom: 0;
                left: -5rem;
                right: auto;
                @include MQ-Below(large) {
                   content: none;
                }
            }
        }
    }

    &__col {
        &__image {
            margin-top: 20px;
            width: var(--text-columns__image__width, 100%);
            @include MQ-Below(medium) {
                width: calc(100% + 3.75rem);
                margin-left: -1.875rem;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__text {
            @extend .text--regular;
            margin-top: 20px;

            a {
                display: block;
                margin-top: 5px;
                text-decoration: underline;
            }
        }

        &__headline {
            @extend .heading--2;
            @include MQ-Below(medium) {
                font-weight: 700;
            }
        }
    }
}
