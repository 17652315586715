.footer {
    background: var(--color__dark-blue);
    color: white;
    z-index: 1;

    a {
        transition: opacity 0.15s ease-in;
        text-decoration: underline;

        &:hover {
            opacity: 0.8;
        }

        &.button {
            text-decoration: none;
            line-height: 1.25rem;
        }
    }

    svg {
        fill: white;
    }

    &__inner {
        @extend .container;
        display: grid;
        grid-gap: 30px;
    }

    &__about {
        @include Grid(2fr 10fr, $sm: 5fr 7fr);
        align-items: center;
        position: relative;
        @include MQ-Below(large) {
            padding: 30px 0;
        }
        @include MQ-Below(medium) {
            padding: 0;
        }

        &:after {
            @include DottedBorder(bottom);
        }

        &__logo {
            max-width: 6.25rem;

            &:focus-within {
                @extend %dashed-focus;
            }
        }

        &__description {
            @extend .text--regular;
            @include MQ-Below(large) {
                font-size: clamp-sizes(1, 1.25);
                line-height: 1.6;
                padding: 1rem 0;
            }
            @include MQ-Below(medium) {
                font-size: clamp-sizes(.85, 1.25);
            }
        }
    }

    &__main {
        @include Grid(1fr 1fr 1fr, $sm_gap: 2.25rem);
        @include MQ-Below(medium) {
            grid-gap: 2.25rem;
        }

        header {
            font-size: 1.375rem;
            font-weight: 500;
            margin-bottom: 1.125rem;
        }
    }

    &__newsletter {
        p {
            @extend .text--regular;
        }

        .button {
            line-height: 1.25rem;
            padding: 10px 30px;

            .button__subline {
                line-height: 0.625rem;
            }
        }
    }

    &__contact {
        ul {
            display: grid;
            grid-gap: 0.75rem;
            @include MQ-Below(medium) {
                grid-gap: 1.688rem;
            }

            li {
                @extend .text--regular;
                @include MQ-Below(medium) {
                    font-size: 1.25rem;
                }

                a {
                    text-decoration: underline;
                    gap: 0.75rem;
                    @include MQ-Below(medium) {
                        text-decoration: none;
                    }
                }

                svg {
                    min-width: 1.75rem;
                    &.icon {
                        opacity: 0.5;
                    }
                }
                .footer__contact__address {
                    a {
                        text-decoration: none;

                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }

        &__address {
            display: flex;
            align-items: flex-start;
            gap: 0.75rem;
            @include MQ-Below(medium) {
                font-size: 1.125rem;
            }

            svg {
                width: auto;
            }

            p {
                line-height: 1.2;
                margin: 0;
            }

            p + p {
                margin-top: 0;
            }
        }
    }

    &__social {
        ul {
            display: flex;
            gap: 1.25rem;
            align-items: center;

            a {
                &:focus-within {
                    @extend %dashed-focus;
                }
            }
        }
    }

    &__legal {
        text-align: center;
        display: flex;
        gap: 1.625rem;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 1.875rem 0;
        flex-direction: column;
        @include MQ-Below(medium) {
            gap: 1.25rem;
        }

        @include MQ-Above(large) {
            flex-direction: row;
        }

        &:before {
            @include DottedBorder();
        }

        a {
            text-decoration: none;

            svg {
                height: 12px;
            }
        }
    }
}
