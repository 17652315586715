:root {
    @include MQ-Above(large) {
        --input__padding: 1rem;
    }
}

input[type="email"],
input[type="text"],
input[type="password"] {
    display: block;
    width: 100%;
    border: none;
    padding: var(--input__padding, 0.6rem);
    background: white;
    font-size: inherit;
    font-family: var(--font-family__primary);
}

input[type='checkbox'] {
    cursor: pointer;
    appearance: none;
    width: 29px;
    height: 29px;
    background: #fff;
    border: 2px solid var(--color__secondary);
    position: relative;

    &:checked {

        &:after {
        content: '✓';
        display: block;
        color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 1.1rem;
        }
    }
}
