/* Imports */

// ITCSS: Settings
@import "./01-settings/functions.scss";
@import "./01-settings/reset.scss";

// ITCSS: Tools
@import "./02-tools/media-queries.scss";
@import "./02-tools/mixins.scss";
@import "./02-tools/tokens.scss";
@import "./02-tools/typography.scss";

// ITCSS: Generic
@import "./03-generic/base.scss";
@import "./03-generic/container.scss";
@import "./03-generic/grid.scss";
@import "./03-generic/theme.scss";

// ITCSS: Elements
@import "./04-elements/a.scss";
@import "./04-elements/article.scss";
@import "./04-elements/button.scss";
@import "./04-elements/columns.scss";
@import "./04-elements/icons.scss";
@import "./04-elements/images.scss";
@import "./04-elements/input.scss";
@import "./04-elements/link.scss";
@import "./04-elements/main.scss";
@import "./04-elements/p.scss";
@import "./04-elements/redactor.scss";
@import "./04-elements/select.scss";
@import "./04-elements/svg.scss";
@import "./04-elements/swipe-view.scss";
@import "./04-elements/toggle.scss";

// ITCSS: Objects
@import "./05-objects/accordion.scss";
@import "./05-objects/blog-list.scss";
@import "./05-objects/button-container.scss";
@import "./05-objects/cards.scss";
@import "./05-objects/careers.scss";
@import "./05-objects/cta.scss";
@import "./05-objects/faded-bg.scss";
@import "./05-objects/form.scss";
@import "./05-objects/gallery.scss";
@import "./05-objects/heading.scss";
@import "./05-objects/hero.scss";
@import "./05-objects/layer.scss";
@import "./05-objects/link-list.scss";
@import "./05-objects/mega-list.scss";
@import "./05-objects/promo.scss";
@import "./05-objects/richtext.scss";
@import "./05-objects/slider.scss";
@import "./05-objects/specs.scss";
@import "./05-objects/testimonial.scss";
@import "./05-objects/text-block.scss";
@import "./05-objects/text-columns.scss";
@import "./05-objects/text-header.scss";
@import "./05-objects/text-with-media.scss";
@import "./05-objects/text.scss";
@import "./05-objects/title.scss";
@import "./05-objects/video-poster.scss";
@import "./05-objects/video.scss";
@import "./05-objects/wysiwyg-text.scss";

// ITCSS: Components
@import "./06-components/admin-buildings.scss";
@import "./06-components/callout.scss";
@import "./06-components/contact-form.scss";
@import "./06-components/events-listing.scss";
@import "./06-components/footer.scss";
@import "./06-components/formie-forms.scss";
@import "./06-components/icon-list.scss";
@import "./06-components/image-with-quote.scss";
@import "./06-components/nav-desktop.scss";
@import "./06-components/nav-mobile.scss";
@import "./06-components/people.scss";
@import "./06-components/site-footer.scss";
@import "./06-components/site-header.scss";
@import "./06-components/site-login.scss";
@import "./06-components/site-mobile-nav-trigger.scss";
@import "./06-components/site-nav.scss";
@import "./06-components/site-social-nav.scss";
@import "./06-components/slider.scss";
@import "./06-components/timeline.scss";

// ITCSS: Templates (our own invention)
@import "./07-templates/t__404.scss";
@import "./07-templates/t__about.scss";
@import "./07-templates/t__article.scss";
@import "./07-templates/t__blog-index.scss";
@import "./07-templates/t__board.scss";
@import "./07-templates/t__contact.scss";
@import "./07-templates/t__event-details.scss";
@import "./07-templates/t__events.scss";
@import "./07-templates/t__locations.scss";
@import "./07-templates/t__login.scss";
@import "./07-templates/t__post.scss";
@import "./07-templates/t__reports.scss";
@import "./07-templates/t__reset-password.scss";
@import "./07-templates/t__resources.scss";

// ITCSS: Utilities
@import "./08-utilities/hacks.scss";
@import "./08-utilities/modal.scss";
@import "./08-utilities/utilities.scss";
@import "./08-utilities/video-wrap.scss";

//Tobii Lightbox
@import '@midzer/tobii/dist/tobii.min.css';

// NPM
@import '~swiper/css';
@import '~swiper/css/navigation';
