.link {
    &--arrow {
        display: inline-block;

        &:hover {
            text-decoration: underline;

            &:after {
                transform: translateX(5px);
            }
        }

        &:after {
            content: "";
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 10px solid currentColor;
            display: inline-block;
            margin-left: 8px;
            transition: transform 0.15s ease-in;
        }

        &:hover {
            text-decoration: underline;
        }

        span {
            margin-left: 2px;
            transition: transform 0.2s ease-in-out;
            display: inline-block;
            svg {
                width: auto;
                height: 100%;
                display: inline-block;
                transform: scale(0.9);
            }
        }

        &:hover {
            // text-decoration: underline;

            span {
                transform: translateX(5px);
            }
        }

        &:focus-within {
            @extend %dashed-focus;
        }
    }
}
