:root {
    @include MQ-Above(large) {
        --site-header__logo__width: 9rem;
    }
}

.site-header {
    background: white;
    z-index: 5;
    color: var(--color__dark-grey);
    width: 100%;
    top: 0;
    transform-origin: top center;
    transition: height 0.15s ease-in;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color__light-grey);
    flex-wrap: wrap;
    @include MQ-Below(medium) {
        position: sticky;
    }

    &__meta {
        @extend .util__desktop-only;
        background: var(--color__dark-blue);
        color: white;
        flex: 0 100%;
        font-weight: 500;
        padding: 4px 0;
        font-size: clamp-sizes(.8, 1);

        &__inner {
            @extend .container;
            display: flex;
            justify-content: space-between;

            &__text {
                p {
                    text-wrap: balance;
                    // max-width: 300px;
                    @include MQ-Below(large) {
                        display: none;
                    }
                    @include MQ-Above(x-large) {
                        text-wrap: none;
                        max-width: 100%;
                    }
                }
            }

            &__links {
                display: flex;
                justify-content: space-between;

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }

                > * + * {
                    margin-left: 20px;
                }

                svg {
                    fill: white;
                    max-height: 24px;
                }
            }
        }
    }

    &__main {
        flex: 0 100%;
        background: white;
        padding: 7px 0;
        position: relative;
        @include MQ-Below(medium) {
            padding: 5px 0;
        }

        &__inner {
            @extend .container;

            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            @include MQ-Below(medium) {
                width: calc(100% - 40px);
            }
        }
    }

    &__logo {
        color: var(--color__blue);
        // width: var(--site-header__logo__width, 6rem);
        width: clamp-sizes(5.688, 6);
        min-width: 10rem;
        transition: opacity 0.15s ease-in, width 0.15s ease-in;

        @include MQ-Below(medium) {
          min-width: 7rem;
        }

        &:hover {
            opacity: 0.6;
        }

        &:focus-within {
            @extend %dashed-focus;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .nav-desktop {
        margin-left: 1rem;
    }

    &__mobile-buttons {
        display: flex;
        align-items: center;
        color: var(--color__blue);
        gap: 1rem;
        @include MQ-Above(navigation) {
            display: none;
        }

        > * + * {
            margin-left: 5px;
        }

        svg {
            height: 35px;
            width: 34px;
            margin-bottom: 2px;
        }
    }
}
