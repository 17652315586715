.t__about {
    .hero--accents {
        @include MQ-Below(medium) {
            .accent--bottom-left {
                z-index: -1;
            }
        }
    }

    .icon-list {
        @include MQ-Below(medium) {
            .accent--bottom-right {
                svg {
                    height: 122px;
                    width: 122px;
                }
            }
        }

        &__inner {
            @include MQ-Below(medium) {
                .accent--top-left {
                    left: -3.75rem;
                    top: -3.75rem;
                    z-index: 0;
                }

                .icon-list__text {
                    padding: 0;
                    z-index: 1;
                }
            }
        }
    }
}
