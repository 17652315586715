.redactor {
    h2 {
        @extend .heading--2;
        margin-bottom: 1.25rem;
    }

    h3 {
        @extend .heading--3;
        margin-bottom: 0.9375rem;
    }

    h4 {
        @extend .heading--4;
        margin-bottom: 0.75rem;
    }

    h5 {
        @extend .heading--5;
        margin-bottom: 0.5rem;
    }

    h6 {
        @extend .heading--6;
        margin-bottom: 0.5rem;
    }

    p {
        @extend .text--regular;
    }

    * + h2 {
        margin-top: 4.375rem;
    }

    * + h3 {
        margin-top: 1.875rem;
    }

    * + h4 {
        margin-top: 1.25rem;
    }

    * + h5 {
        margin-top: 1.25rem;
    }

    * + h6 {
        margin-top: 1rem;
    }
}
