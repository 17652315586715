/*
 * Special object used to wrap long running copy delivered by the CMS
 *
 * Traditional typographic elements like ul gain automatic styling
 * Neighbors get pleasant margins to allow for easy reading.
 * Headings get automatic styling based on the .title class.
 */

:root {
    --wysiwyg-text__font-size: 1rem;
    --wysiwyg-text__list-gutter: 0.25rem;
    --wysiwyg-text__list__margin-left: 3rem;

    // @include MQ-Above(medium) {
    //     --wysiwyg-text__list-gutter: 0.8rem;
    // }

    // @include MQ-Above(large) {
    //     --wysiwyg-text__list-gutter: 1rem;
    // }
}

.wysiwyg-text {
    font-size: var(--wysiwyg-text__font-size);

    // Universal spacer
    // All spacing is a mutiple of the font-size, so that it scales as the base font-size scales. Can't use `em` because some components have different font sizes which would affect spacing unequally
    > * + * {
        // stylelint-disable-line
        margin-top: calc(var(--wysiwyg-text__font-size) * 1.25);
        @include MQ-Below(medium) {
            margin-top: 0;
        }
    }

    /*
     * Headings
     * Automatically add classes, and tweak to work well as a set
     */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        // Before headings, add more space.
        // &:not(:first-child) {
        //     margin-top: 1.2rem;
        // }
        font-weight: 600;
        @include MQ-Below(medium) {
            font-weight: 500;
        }
    }

    h1 {
        @extend .title--primary;
    }

    h2 {
        @extend .heading--2;
        margin-bottom: 1.25rem;
    }

    h3 {
        @extend .heading--3;
        margin-bottom: 0.9375rem;
    }

    h4 {
        @extend .heading--4;
        margin-bottom: 0.75rem;
    }

    h5 {
        @extend .heading--5;
        margin-bottom: 0.5rem;
    }

    h6 {
        @extend .heading--6;
        margin-bottom: 0.5rem;
    }

    p {
        @extend .text--regular;
    }

    * + h2 {
        margin-top: 4.375rem;
    }

    * + h3 {
        margin-top: 3.875rem;
        @include MQ-Below(medium) {
            margin-top: 2.875rem;
        }
    }

    * + h4 {
        margin-top: 2.25rem;
        @include MQ-Below(medium) {
            margin-top: 1.25rem;
        }
    }

    * + h5 {
        margin-top: 2.25rem;
        @include MQ-Below(medium) {
            margin-top: 1.25rem;
        }
    }

    * + h6 {
        margin-top: 2rem;
        @include MQ-Below(medium) {
            margin-top: 1rem;
        }
    }

    /*
     * Styling for lists, which are meant to be lists of content in this case rather than being used just for semantic meaning
     */

    ul,
    ol {
        display: grid;
        grid-gap: var(--wysiwyg-text__list-gutter);
        margin-left: var(--wysiwyg-text__list__margin-left);
        max-width: 80%;
        line-height: 1.6;
        font-size: 1em;
        color: var(--color__black--faded-60);

        li {
            display: list-item;
            > ul,
            > ol {
                --wysiwyg-text__list__margin-left: 2rem;
            }

            a {
                display: inline;
            }
        }
    }

    ul > li {
        list-style-type: disc;
        > ul {
            margin-top: 1rem;
        }
    }

    ol > li {
        list-style-type: decimal;
        > ol {
            margin-top: 1rem;
            li {
                list-style-type: lower-alpha;
                > ol {
                    li {
                        list-style-type: lower-roman;
                    }
                }
            }
        }
    }

    blockquote {
        @extend .title;
        @extend .title--secondary;

        --title__color: var(--color__blue);

        width: 80%;
        text-align: center;
        margin: 2rem auto 2rem auto;
        // margin-left: auto;
        // margin-right: auto;

        &::before {
            content: "“";
        }

        &::after {
            content: "”";
        }
    }

    figure {
        img,
        iframe,
        video {
            margin: 2rem auto 2rem auto;
        }
    }

    /*
     * Special margins for unique combinations
     */

    > * + img,
    > * + figure,
    > img + *,
    > figure + * {
        margin-top: calc(var(--wysiwyg-text__font-size) * 2.5);
    }

    > a img,
    > a figure {
        margin-top: calc(var(--wysiwyg-text__font-size) * 1.75);
        margin-bottom: calc(var(--wysiwyg-text__font-size) * 1.75);
    }

    * + hr,
    hr + * {
        margin-top: calc(var(--wysiwyg-text__font-size) * 3);
    }

    p {
        color: var(--color__blue);
    }

    ul + p {
        margin-top: 1.5rem;
    }

    a {
        color: var(--color__blue);
        word-break: break-word;
        text-decoration: underline;
        &:hover {
            color: var(--color__primary);
        }

        &.button {
            text-decoration: none;
            &:hover {
                box-shadow: -1px 2px 1px rgba(0, 0, 0, 0.2);
                color: var(--color__blue);
                opacity: 0.9;
            }
        }
    }

    .button + .link--arrow {
        margin-top: 1.125rem;
    }
}
