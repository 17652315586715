// Universal hero styles
.hero {
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--color__bright-blue);
    color: white;
    padding: 90px 0;
    overflow: hidden;
    @include MQ-Below(medium) {
        padding: 70px 0;
    }

    &__meta {
        color: white;

        h1 {
            @extend .heading--1;
            margin-bottom: 0.625rem;
        }

        a {
            &:focus-within {
                @extend %dashed-focus;
                outline-color: white;
            }
        }
    }

    &__breadcrumbs {
        margin-bottom: 1.25rem;

        ol {
            display: flex;
            align-items: center;

            li {
                text-transform: uppercase;
                color: #fff;
                font-weight: 600;
                font-size: 0.75rem;
                display: flex;
                align-items: center;

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            li + li {
                &:before {
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 5L0.5 9.33013L0.5 0.669872L8 5Z' fill='%23FFF'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    width: 7px;
                    height: 9px;
                    display: inline-block;
                    margin: -1px 9px 0 10px;
                }
            }
        }
    }

    &__secondary-links {
        @extend .text--regular;
        margin-top: 0.625rem;
        display: grid;
        gap: 5px;

        a {
            @extend .cta--secondary;

            svg {
                width: auto;
                max-height: 1.375rem;
                margin-right: 0.3125rem;
            }
        }
    }
}

// Default hero styles
.hero--default {
    .hero__inner {
        @extend .container;
        @include Columns(2);
        @include Gap(2.5rem, $lg: 3.75rem);
        padding-right: 2rem;
        z-index: 1;
    }

    .hero__text {
        @extend .text--medium;

        @include MQ-Above(medium) {
            &:before {
                content: "";
                display: block;
                width: 50%;
                height: 3px;
                background: var(--color__bright-yellow);
                margin-bottom: 30px;
            }
        }

        p {
            line-height: 1.875rem;
            margin-bottom: 0.75rem;
            &:last-of-type {
                margin-bottom: 0;
            }

            @include MQ-Below(medium) {
                font-size: 1rem;
                line-height: 1.2rem;
            }

            strong {
                @include MQ-Below(medium) {
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                }
            }
        }
    }

    .hero--accents {
        z-index: 0;

        .accent--top-left {
            position: absolute;
            top: 0;
            left: 0;
        }
        .accent--bottom-left {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .accent--top-right {
            position: absolute;
            top: 0;
            right: 0;
        }
        .accent--bottom-right {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}

// Video hero styles
.hero--video {
    @include MQ-Below(medium) {
        padding-top: 45px;
        padding-bottom: 120px;
    }

    .hero__inner {
        @extend .container;
        @include Grid(4fr 8fr);
        @include Gap(2.5rem, $lg: 3.75rem);
    }

    .hero__text {
        @extend .text--regular;
    }

    .hero__meta {
        @include MQ-Below(medium) {
            grid-row: 2;
        }
    }

    .hero__media {
        position: relative;

        .hero--accents {
            .accent--bottom-right {
                position: absolute;
                bottom: -80px;
                right: -70px;
            }
        }
    }

    .hero__video {
        position: relative;
        @include MQ-Below(medium) {
            padding-bottom: 100%;
        }

        &:before {
            content: "";
            background: url(/assets/img/diagonal-lines.svg) no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            width: 40vw;
            height: 40vw;
            top: -10px;
            left: -10px;
        }

        @include MQ-Above(large) {
            &:before {
                width: 24vw;
                height: 21vw;
                top: -30px;
                left: -30px;
            }
        }

        .glightbox {
            position: absolute;
            inset: 0;

            &:focus-within {
                @extend %dashed-focus;
                outline-color: white;
            }
        }
    }
}
