.specs {
  &__inner {
    @include Container();
  }

  &--with-media {
    @include Grid(2, $repeat: true);

    .specs__content {
      margin-bottom: 1.875rem;
    }
  }

  &__content {
    ul {
      @include Grid(2, $repeat: true);

      li {
        border-top: 1px dotted black;
        padding: 0.625rem 0 1.25rem 0;
      }
    }
  }
}
