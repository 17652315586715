:root {
    @include MQ-Above(large) {
        --text-with-media__margin: -5rem;
        --text-with-media__padding: 3.75rem 0;
        --text-with-media__media__padding: 0 3.75rem 0 0;
        --text-with-media--flipped__media__padding: 0 0 0 3.75rem;
    }
}

.text-with-media {
    position: relative;

    &__inner {
        @include Columns(2);
        @include Container();
        @include Gap();
        padding: var(--text-with-media__padding, 0 0 3.75rem 0);
        @include MQ-Below(medium) {
            padding: 0 0 2.188rem 0;
        }
    }

    &__media {
        padding: var(--text-with-media__media__padding, 0);
        margin-top: var(--text-with-media__margin, 2rem);
        @include MQ-Below(medium) {
            width: calc(100% + 3.75rem);
            margin-left: -1.875rem;
            margin-top: 0;
        }
    }

    &__text {
        max-width: 512px;
        @include MQ-Below(large) {
            padding: var(--text-with-media__media__padding, 0);
            margin-top: var(--text-with-media__margin, 2rem);
        }
        @include MQ-Below(medium) {
            padding: 0;
            margin-top: 0;
        }

        h2 {
            @include MQ-Below(medium) {
                font-weight: 700;
            }
        }
    }

    &__links {
        margin-top: .75rem !important;
        > a.link--arrow {
            padding: .5rem 0;
            display: flex;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 0;
            gap: 0.5rem;
            align-items: center;
            text-decoration: none;
            width: max-content;
            @include MQ-Below(medium) {
                font-size: 1.125rem;
            }

            > svg {
                fill: #01416E;
                @include MQ-Below(medium) {
                    fill: var(--color__bright-blue);
                }

                &:nth-of-type(1n) {
                    height: 20px;
                    width: 20px;
                }
            }

            span > svg {
                height: 12px;
                width: 12px;
            }
        }
    }

    &--flipped {
        .text-with-media__text {
            order: 1;
        }

        .text-with-media__media {
            order: 2;
            padding: var(--text-with-media--flipped__media__padding, 0);
        }
    }
}
