.t__events {
    .hero--accents {
        .accent--top-left {
            left: -2px;
            @include MQ-Below(medium) {
                left: -8px;

                svg {
                    height: 163px;
                    width: 28px;
                }
            }
        }

        .accent--bottom-right {
            bottom: -25px;
            @include MQ-Below(medium) {
                bottom: auto;
                top: 232px;

                svg {
                    height: 18px;
                    width: 128px;
                }
            }
        }
    }
}
