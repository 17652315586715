@use "sass:math";

.nav-mobile {
    display: block;
    position: fixed;
    top: 129px;
    left: 0;
    width: 100%;
    background: white;
    height: calc(100% - 129px);
    overflow: auto;
    z-index: 100;
    transition: opacity 0.25s ease-in;
    visibility: hidden;
    opacity: 0;
    @include MQ-Above(navigation) {
        display: none;
    }
    @include MQ-Below(medium) {
        top: 66px;
        height: calc(100% - 66px);
    }

    &[aria-hidden="false"] {
        opacity: 1;
        visibility: visible;
    }

    > ul {
        > li {
            border-bottom: 1px solid var(--color__dark-grey);
            &:first-of-type {
                border-top: 1px solid var(--color__dark-grey);
            }
            &:hover, &:active {
                // background: var(--color__light-grey);
            }
        }

        a {
            flex: 1 auto;
        }
    }

    &__dropdown {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &__menu {
            flex: 0 100%;
            border-top: 0px solid var(--color__dark-grey);
            max-height: 0px;
            visibility: hidden;
            overflow: hidden;
            transition: .5s ease max-height, .5s ease visibility, 0 border-top .5s;

            .nav-mobile__link {
                font-size: 1rem;
                font-weight: 400;
            }
        }

        &--active {
            > .nav-mobile__link {
                font-weight: 700;
            }
        }
    }

    &__button {
        flex: 0 50px;
        min-height: 100%;
        border-left: 1px solid var(--color__dark-grey);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &[aria-expanded="true"] {
            ~ .nav-mobile__dropdown__menu {
                visibility: visible;
                border-top: 1px solid var(--color__dark-grey);
                height: 100%;
                max-height: 999px;
                transition: .75s ease max-height, .75s ease visibility;
            }

            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            transition: transform 0.15s ease-in;
        }
    }

    &__link {
        padding: 1rem;
        font-size: 1.375rem;
        font-weight: 500;
        color: var(--color__blue);
    }

    // Override featured class which comes from CMS
    .nav-desktop__link--featured {
        background: inherit;
        border-radius: none;
        box-shadow: none;
    }

    // Compnay info section
    &__meta {
        background: var(--color__orange);
        color: var(--color__dark-blue);
        padding: 20px;
        font-size: 1.125rem;
        font-weight: 400;

        .util__has-icon {
            font-weight: 500;
            margin-top: 1.5rem;
            gap: 0.5rem;

            svg {
                fill: var(--color__dark-blue);
                max-height: 24px;
            }
        }
    }

    // Trigger
    $trigger-layer-width: 32px;
    $trigger-layer-height: 4px;
    $trigger-layer-spacing: 6px;

    &__trigger {
        padding: 15px 0;
        display: inline-block;
        cursor: pointer;

        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;

        // Normalize (<button>)
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;
        @include MQ-Above(navigation) {
            display: none;
        }

        &[aria-expanded="true"] {
            .nav-mobile__trigger__inner,
            .nav-mobile__trigger__inner::before,
            .nav-mobile__trigger__inner::after {
                background-color: var(--color__blue);
            }

            .nav-mobile__trigger__inner {
                transform: rotate(45deg);
                transition-delay: 0.12s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

                &::before {
                    top: 0;
                    opacity: 0;
                    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
                }

                &::after {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition: bottom 0.075s ease,
                        transform 0.075s 0.12s
                            cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
        }
    }

    &__trigger__box {
        width: $trigger-layer-width;
        height: $trigger-layer-height * 3 + $trigger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }

    &__trigger__inner {
        display: block;
        top: 50%;
        margin-top: math.div($trigger-layer-height, -2);
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &,
        &::before,
        &::after {
            width: $trigger-layer-width;
            height: $trigger-layer-height;
            background-color: var(--color__blue);
            border-radius: 4px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: ($trigger-layer-spacing + $trigger-layer-height) * -1;
            transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        }

        &::after {
            bottom: ($trigger-layer-spacing + $trigger-layer-height) * -1;
            transition: bottom 0.075s 0.12s ease,
                transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }
}
