.t__reports {
    .hero--default {
        @include MQ-Above(medium) {
            padding: 5.625rem 0 10.625rem 0;
        }

        .hero--accents {
            .accent--top-left {
                @include MQ-Below(medium) {
                    svg {
                        width: 48px;
                        height: 55px;
                    }
                }
            }

            .accent--bottom-right {
                @include MQ-Below(medium) {
                    bottom: -35px;
                    right: -50px;
                    transform: rotate(90deg);

                    svg {
                        width: 78px;
                        height: 78px;
                    }
                }
            }
        }
    }

    h2 {
        @extend .heading--2;
        @include MQ-Below(medium) {
            font-weight: 700;
        }
    }

    .gallery-slider {
        &__media {
            margin-top: 0;
        }

        &:nth-of-type(2) {
            .gallery-slider__media {
                margin-top: -5.2rem;
                @include MQ-Below(medium) {
                    margin-top: 0;
                }
            }
        }
    }

    .highlights {
        position: relative;
        background: var(--color__light-orange);
        padding: var(--spacing) 0;
        @include MQ-Below(medium) {
            padding: 2.5rem 0 8.313rem 0;
        }

        h2 {
            margin-bottom: var(--spacing);
            text-align: center;
        }

        &__accents {
            .accent {
                position: absolute;
                &-1 {
                    top: 171px;
                    left: 0;
                    bottom: auto;
                    right: auto;
                    @include MQ-Below(medium) {
                        top: 355px;
                        left: -70px;
                    }
                }

                &-2 {
                    top: 50%;
                    right: 0;
                    transform: translate(0%, -50%);
                    @include MQ-Below(medium) {
                        svg {

                        }
                    }
                }

                &-3 {
                    right: auto;
                    top: auto;
                    bottom: 26px;
                    left: -18px;
                    @include MQ-Below(medium) {
                        bottom: 19px;
                        left: -11px;
                        svg {
                            width: 217px;
                            height: 75px;
                        }
                    }
                }
            }
        }

        &__inner {
            @include Container(944px);
        }

        &__items {
            .highlight {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 3rem;
                @include MQ-Below(medium) {
                    flex-direction: column;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                &.image--right {
                    flex-direction: row-reverse;
                    @include MQ-Below(medium) {
                        flex-direction: column;
                    }

                    .highlight__content {
                        @include MQ-Above(medium) {
                            margin-right: -10px;
                        }
                    }
                }

                &.image--left {
                    .highlight__content {
                        @include MQ-Above(medium) {
                            margin-left: -10px;
                        }
                    }
                }

                &__content {
                    flex: 2;
                    background: var(--color__bright-blue);
                    color: #fff;
                    font-size: 1.5rem;
                    line-height: 2.125rem;
                    padding: 1.875rem;
                    z-index: 1;
                    @include MQ-Below(medium) {
                        font-size: 1.375rem;
                        line-height: 1.875rem;
                        padding: 1.438rem 1.313rem;
                        max-width: 80%;
                        margin-top: -1.8rem;
                    }
                }

                &__image {
                    flex: 1;
                    border: 3px solid #fff;
                    filter: drop-shadow(2px 2px 9px rgba(0, 0, 0, 0.2));
                    @include MQ-Above(medium) {
                        min-width: 375px;
                        z-index: 10;
                    }
                }
            }
        }
    }

    .charts {
        position: relative;
        padding: var(--spacing) 0;
        @include MQ-Below(medium) {
            padding: var(--spacing) 0;
        }

        h2 {
            margin-bottom: var(--spacing);
            text-align: center;
            @include MQ-Below(medium) {
                margin-bottom: 2.5rem;
            }
        }

        h3 {
            font-size: 1.875rem;
            font-weight: 500;
            line-height: 2.375rem;
            text-align: center;
            @include MQ-Below(medium) {
                font-size: 1.375rem;
                line-height: 1.625rem;
            }

        }

        &__inner {
            @include Container;
        }

        .chart {
            padding: 2.5rem;
            border: 3px solid #177ABC;
            margin-bottom: 1.25rem;
            @include MQ-Below(medium) {
                border: none;
                padding: 0;
                margin-bottom: 3.75rem;
            }
            &:last-of-type {
                margin-bottom: 0;
            }

            h3 {
                margin-bottom: 1.25rem;
            }

            &__pie {
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                @include MQ-Below(medium) {
                    flex-direction: column;
                }
            }

            &__canvas {
                flex-basis: 348px;
                max-width: 348px;
                margin-right: 5rem;
                @include MQ-Below(medium) {
                    margin-right: 0;
                    margin-bottom: 2.5rem;
                }
            }

            &__legend {
                flex: 1;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0.688rem;
                @include MQ-Below(medium) {
                    width: 100%;
                    grid-template-columns: 1fr;
                    gap: 0.625rem;
                }

                &-item {
                    display: grid;
                    grid-template-columns: 16px 1fr;
                    column-gap: 0.625rem;

                    dd {
                        color: var(--color__bright-blue);
                        font-size: 1.25rem;
                        font-weight: 600;
                        line-height: 1.5rem;
                        grid-column: 2;
                        grid-row: 1;
                        margin-top: -4px;
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 1.25rem;
                        grid-column: 2;
                        grid-row: 2;
                    }
                }
            }

            .colour-swatch {
                grid-column: 1;
                grid-row: 1 / 2;
                height: 16px;
                width: 16px;
                background-color: #177ABC;
            }
        }
    }
}
