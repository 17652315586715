.form-control {
    &__group,
    .fui-row {
        display: flex;
        align-items: center;
        margin: 1rem -.5rem;
        @include MQ-Below(medium) {
            flex-wrap: wrap;
        }

        > .form-control__item {
            flex: 1;
            margin: 0 0.5rem;
            @include MQ-Below(medium) {
                flex-basis: 100%;
            }
        }
    }

    &__item {
        label {
            display: block;
            margin-bottom: .5rem;

            span {
                color: #000;
                font-size: .85rem;
            }
        }

        input, textarea {
            background-color: rgba(23, 122, 188, 0.1);
            border: 3px solid var(--color__secondary);
            padding: .65rem;
            width: 100%;
        }
    }

    &__checkbox {
        display: flex;
        align-items: center;
        gap: .5rem;
        margin-left: .25rem;
    }
}

.form-note{
    margin-top: 2.2rem;
    font-style: italic;
}
