:root {
    --video__button-height: 45px;

    @include MQ-Above(medium) {
        --video__button-height: 85px;
    }
}

.video {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    background-color: black; // Default background color helps hold the space before poster image loads and during the transition between the poster image and video load

    iframe,
    video,
    figure {
        @extend .util__fill;
    }

    figure {
        picture {
            height: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &__button {
        position: absolute;
        bottom: var(--spacing-s);
        left: var(--spacing-s);
        z-index: 1;
        display: flex;
        align-items: center;
        color: white;

        p {
            margin-left: 0.5rem;
            font-weight: 600;
            font-size: 1.25rem;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }

        svg {
            height: var(--video__button-height);
            width: auto;
            transition: transform 0.2s ease-in-out;
        }
    }

    &:hover {
        .video__button {
            svg {
                transform: scale(1.1);
            }
        }
    }
}
