/* Tobii Lightbox */
.tobii-zoom__icon {
    display: none;
}

.tobii__slide figure>img {
    padding: 2rem;
}

.tobii-zoom {
    position: initial;
}
