.button {
    background: var(--color__orange);
    color: var(--color__blue);
    font-size: 1rem;
    text-align: center;
    padding: 0.75rem 1.875rem;
    font-weight: 600;
    border-radius: 6px;
    box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.15s ease-in, opacity 0.15s ease-in;

    .svg--arrow {
        display: inline-block;
        transition: transform 0.15s ease-in;

        svg {
            height: 11px;
            width: 11px;
        }
    }

    &:hover, &:focus-within {
        box-shadow: -1px 2px 1px rgba(0, 0, 0, 0.2);
        opacity: 0.9;

        .svg--arrow {
            transform: translateX(5px);
        }
    }

    &__subline {
        display: block;
        font-size: 11px;
        font-weight: 400;
        margin-top: 3px;
    }

    &__wide {
        width: 100%;
    }

    &__back-to-top {
        background-color: #fff;
        position: fixed;
        inset: auto 1rem 1rem auto;
        padding: 0.75rem 1rem;
        opacity: 1;
        visibility: visible;
        z-index: 10;
        transition: .3s ease opacity;
        @include MQ-Below(medium) {
            display: none;
        }

        &.hidden {
            visibility: hidden;
            opacity: 0;
        }

        svg {
            fill: var(--color__blue);
            width: 1rem;
        }
    }
}
