.careers {
    &__apply {
        .careers__columns {
            display: flex;
            gap: 3.75rem;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: -3.75rem;
            padding: 0 0 3.75rem 0;
            width: calc(100% - 7.5rem);
            max-width: var(--max-width);
            @include MQ-Below(medium) {
                flex-direction: column;
                gap: 1.5rem 0;
                width: calc(100% - 3.5rem);
                margin-top: 0;
            }

            > * {
                width: calc(100% / 2);
                @include MQ-Below(medium) {
                    width: 100%;
                }

                &:first-child {
                    max-width: 500px;
                }
            }
        }
    }

    &__content {

        .none-available {
            border: 3px solid #177ABC;
            background: rgba(23, 122, 188, 0.1);
            padding: 1rem;
            text-align: center;
            font-weight: 500;
            font-size: 1.375rem;
            line-height: 1.625rem;

            a {
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
}
