.t__resources {

    h2 {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 3rem;
        margin: 1.25rem 0;
        @include MQ-Below(medium) {
            font-size: 1.75rem;
            line-height: 2.125rem;
            font-weight: 700;
            max-width: 85%;
        }
    }

    h3 {
        display: inline;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.625rem;
        margin-bottom: 0.625rem;
    }

    .resources {
        padding: var(--spacing) 0;

        &__info {

            > img {
                height: 60px;
                width: 60px;
                @include MQ-Below(medium) {
                    height: 35px;
                    width: 35px;
                }
            }

            p {
                font-size: 1.125rem;
                line-height: 1.75rem;
            }
        }

        &__list {
            margin-top: 5rem;
            @include MQ-Below(medium) {
                margin-top: 0;
            }

            article {
                padding: 1.875rem 0;
                @include MQ-Below(medium) {
                    padding: 1.688rem 0;
                }

                &:last-of-type {
                    padding: 1.875rem 0 0;
                }

                &:first-of-type {
                    padding: 0 0 1.875rem 0;
                }
            }

            hr {
                margin: 0;
                height: 3px;
                width: 100%;
                background: url(/assets/img/dot.svg) repeat;
                border: none;

                &:last-of-type {
                    display: none;
                }
            }
        }

        &__banner {
            margin-top: 5rem;
            @include MQ-Below(medium) {
                margin-top: 2rem;
                margin-left: -30px;
                width: calc(100% + 60px) !important;
            }
        }

        &--has-image {
            padding: var(--spacing) 0;
        }
    }

    .resources,
    .resources--has-image {

        &:nth-of-type(2n+1) {
            background: var(--color__light-blue);
            color: var(--color__blue);
        }

        .container {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            gap: 4.375rem;
            @include MQ-Below(medium) {
                flex-direction: column;
                gap: 2.5rem;
            }

            > * {
                flex-basis: calc((100%/2) - 2.188rem);
                @include MQ-Below(medium) {
                    width: 100%;
                }
            }
        }

        .link--arrow {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            span {
                margin-top: -0.625rem;

                svg {
                    height: 16px;
                    width: 14px;
                }
            }

            &::after {
                content: none;
            }
        }
    }

    .resources + .resources--has-image {
        padding-top: var(--spacing);
    }
}
