:root {
    --swipe-view-li-margin: 10px;
}

.swipe-view {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    flex: 1;
    display: flex;
    -webkit-overflow-scrolling: touch;
    margin: calc(var(--swipe-view-li-margin) * -1);

    > li {
        width: 70vw;
        flex: 0 0 70vw;
        scroll-snap-align: start;
        padding: var(--swipe-view-li-margin);
    }

    &--columns--two {
        @include MQ-Above(large) {
            > li {
                width: 50%;
                flex: 0 0 50%;
            }
        }
    }

    &--columns--three {
        @include MQ-Above(large) {
            > li {
                width: 33.33%;
                flex: 0 0 33.33%;
            }
        }
    }

    &--columns--five {
        @include MQ-Above(large) {
            > li {
                width: 20%;
                flex: 0 0 20%;
            }
        }
    }
}
