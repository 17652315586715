.t__blog-index {
    background: var(--color__faint-grey);

    &__inner {
        @include Container();
    }

    &__pagination {
        margin: 2rem auto;
        text-align: center;

        a {
            margin: 0 1rem;
        }
    }

    .cards__list {
        &__card {
            transition: border 0.3s ease-in;
            border: 1px solid var(--color__light-grey);

            &:hover {
                border: 1px solid var(--color__primary);
            }
        }
    }
}
