.gallery {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__primary {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__thumbnails {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        img {
            width: 100%;
            height: auto;
        }
    }
}
