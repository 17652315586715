:root {
    --container--full: 100%;
    --container--xl: 90%;
    --container--large: 83%;
    --container--medium: 70%;
    --container--small: 55%;
    --container--xs: 40%;
}

.container {
    width: calc(100% - 60px);
    max-width: var(--max-width);
    margin: 0 auto;

    @include MQ-Above(large) {
        width: calc(100% - 120px);
    }

    &--xl {
        max-width: var(--container--xl);
    }

    &--full {
        max-width: var(--container--full);
    }

    &--large {
        max-width: var(--container--large);
    }

    &--medium {
        max-width: var(--container--medium);
    }

    &--small {
        max-width: var(--container--small);
    }

    &--xs {
        max-width: var(--container--xs);
    }

    &--max {
        max-width: var(--max-width);
        width: 100%;

        @include MQ-Above(small) {
            width: 90%;
        }
    }
}

.md\:container {
    @include MQ-Above(large) {
        &--xl {
            max-width: var(--container--xl);
        }

        &--full {
            max-width: var(--container--full);
        }

        &--large {
            max-width: var(--container--large);
        }

        &--medium {
            max-width: var(--container--medium);
        }

        &--small {
            max-width: var(--container--small);
        }

        &--xs {
            max-width: var(--container--xs);
        }
    }
}

.lg\:container {
    @include MQ-Above(large) {
        &--xl {
            max-width: var(--container--xl);
        }

        &--full {
            max-width: var(--container--full);
        }

        &--large {
            max-width: var(--container--large);
        }

        &--medium {
            max-width: var(--container--medium);
        }

        &--small {
            max-width: var(--container--small);
        }

        &--xs {
            max-width: var(--container--xs);
        }
    }
}
