.testimonial {
    position: relative;

    &__inner {
        @include Container(medium);

        position: relative;
        z-index: 1;
    }

    &__text {
        &__quote {
            @extend .title, .title--tertiary;

            margin-bottom: 1rem;
        }

        &__title {
            font-size: 0.875rem;
        }
    }

    &--with-media {
        @include Grid(2, $repeat: true);

        align-items: center;
    }
}
