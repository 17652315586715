.promo {
  position: relative;
  text-align: center;

  &__inner {
    @include Container(small);

    position: relative;
    z-index: 1;
  }
}
