// TODO: MAKE SOMETHING NOT GARBAGE

.gallery-slider {
    position: relative;

    &__media {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        margin-top: -5.2rem;
        max-width: 500px;
        @include MQ-Below(medium) {
            max-width: none;
            margin-top: -5rem;
        }

        .slider-primary {
            max-width: 500px;

            .swiper-wrapper {
                aspect-ratio: 250/161;
            }
        }

        .slider-thumbnails {
            max-width: 500px;

            .swiper-wrapper {
                aspect-ratio: 500/98;
            }

            .swiper-slide-thumb-active {
                outline: 4px solid #F1620D;
                outline-offset: -4px;
            }
        }

        .swiper-button-next, .swiper-button-prev {
            color: white;
        }

        .swiper-notification {
            display: none;
        }

        .swiper-slide {
            .video-wrap {
                padding-top: calc(56.25% + 2.625rem);
            }
        }
    }

    &__text {

    }

    .rando-columns {
        display: flex;
        gap: 3.75rem;
        justify-content: space-between;
        margin: 0 auto;
        padding: 3.75rem 0;
        width: calc(100% - 7.5rem);
        max-width: var(--max-width);
        @include MQ-Below(medium) {
            flex-direction: column;
            gap: 1.5rem 0;
            width: calc(100% - 3.5rem);
        }

        > * {
            width: calc(100% / 2);
            @include MQ-Below(medium) {
                width: 100%;
            }
        }

        &-reversed {
            flex-direction: row-reverse;
            @include MQ-Below(medium) {
                flex-direction: column;
            }
        }

        blockquote {
            font-weight: 600;
            margin: 0;
            text-align: left;
            width: 100%;
        }
    }

    &--single {
        .rando-columns {
            @include MQ-Below(medium) {
                padding-top: 0;
            }
        }

        .gallery-slider__media {
            @include MQ-Below(medium) {
                margin-top: 0;
                width: calc(100% + 3.5rem);
                margin-left: -1.75rem;
            }
        }
    }

    &__credit {
        font-size: 1.375rem;
        font-weight: 500;
        line-height: 1.75rem;
        margin-top: 1.5rem;
        @include MQ-Below(medium) {
            font-size: 1rem;
            line-height: 1.375rem;
            margin-top: 1.875rem;
        }
    }
}

.gallery-slider + .careers__apply {
    @include MQ-Below(medium) {
        margin-top: -3rem;
    }
}
