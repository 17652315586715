.site-mobile-nav-trigger {
    position: relative;
    z-index: 2;
    color: black;
    height: 1.125rem;
    width: 1.125rem;

    &:hover {
        opacity: 0.6;
    }

    // Hamburger icon created with psuedo-elements
    &__icon {
        position: relative;
        display: flex;
        color: currentColor;
        background: currentColor;
        height: 2px;
        width: 100%;
        transition: var(--transition);
        transition-property: transform;

        &::before,
        &::after {
            content: "";
            position: absolute;
            background: currentColor;
            height: 100%;
            width: 100%;
        }

        &::before {
            bottom: var(
                --site-header__site-mobile-nav-trigger__icon-spacing,
                0.3125rem
            );
        }

        &::after {
            top: var(
                --site-header__site-mobile-nav-trigger__icon-spacing,
                0.3125rem
            );
        }
    }

    &[aria-expanded="true"] {
        // Turn hamburger into an 'X' when active
        --site-header__site-mobile-nav-trigger__icon-spacing: 0;
        color: var(--color__primary);

        .site-mobile-nav-trigger__icon {
            transform: rotate(-45deg);

            &::before {
                transform: rotate(-90deg);
            }

            &::after {
                display: none;
            }
        }
    }
}
