.admin-buildings {
    padding: var(--spacing-l) 0;

    h2 {
        @extend .heading--2;
        font-weight: 700;
        text-align: center;
        margin-bottom: var(--spacing-l);
    }

    h3 {
        @extend .heading--4;
        margin-bottom: var(--spacing-s);
        margin-top: var(--spacing-s);
    }

    &__inner {
        @extend .container;
        @include Columns(2, $sm: 1, $lg: 3);
        gap: 3.5rem;
        @include MQ-Above(medium) {
            @include Gap(2.5rem, $lg: 2.75rem);
        }

        > * {
            position: relative;
            &::before {
                content: '';
                width: 100%;
                height: 3px;
                position: absolute;
                top: auto;
                bottom: -1.75rem;
                left: 0;
                right: 0;
                border-bottom: 4px dotted var(--color__orange);
                @include MQ-Above(medium) {
                    content: none;
                }
            }
            &:last-of-type {
                &::before {
                    content: none;
                }
            }
        }
    }

    &__info {
        background: var(--color__light-grey);
        padding: var(--spacing-s);
    }

    &__address {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: var(--spacing-s);

        svg {
            margin-top: 5px;
        }
    }

    &__contact {
        h4 {
            margin: 1rem 0 .5rem 0;
            font-weight: 500;
        }

        a {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            margin-bottom: .5rem;
        }

        .link--arrow {
            &::after {
                content: none;
            }

            span {
                svg {
                    height: 11px;
                    width: 11px;
                }
            }
        }
    }

    &__details {
        margin-top: var(--spacing-s);
        p {
            margin-bottom: var(--spacing);
            &:last-of-type {
                margin: 0;
            }
        }
    }

    &--sideways {
        padding: var(--spacing) 0;

        h2 {
            font-weight: 600;
            @include MQ-Below(medium) {
                font-weight: 700;
                text-align: left;
                max-width: 85%;
                margin-bottom: 1.5rem;
            }
        }

        h3 {
            @include MQ-Below(medium) {
                margin: 0.25rem 0;
            }
        }

        h4 {
            font-size: 1rem;
        }

        .admin-building {
            display: flex;
            flex-direction: row;
            gap: 1.25rem;
            @include MQ-Below(medium) {
                flex-direction: column-reverse;
            }

            &__description {
                flex: 1;

                p {
                    @include MQ-Below(medium) {
                        font-size: 0.875rem;
                    }
                }
            }

            &__image {
                max-width: 220px;
                @include MQ-Below(medium) {
                    max-width: 95%;
                }
            }

            .admin-buildings__info {
                font-size: 0.813rem;
                line-height: 1.125rem;
                padding: 1rem;
                height: 100%;
                width: 220px;
                @include MQ-Below(medium) {
                    width: 95%;
                }

                .link--arrow {
                    span {
                        svg {
                            width: 11px;
                            height: 11px;
                            max-width: 11px;
                        }
                    }

                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    hr {
        margin: 2.875rem 0;
        height: 3px;
        width: 100%;
        background: url(/assets/img/dot.svg) repeat;
        border: none;
        @include MQ-Below(medium) {
            margin: 1.875rem 0;
        }

        &:last-of-type {
            display: none;
        }
    }
}
