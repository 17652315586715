.t__event-details {
    .hero--accents {
        .accent--top-left {
            @include MQ-Below(medium) {
                left: -9px !important;
                svg {
                    height: 163px;
                }
            }
        }
        .accent--bottom-right {
            @include MQ-Below(medium) {
                bottom: 100px !important;
                svg {
                    width: 128px;
                }
            }
        }
    }
}
