.contact-form {
    position: relative;
    padding: var(--spacing) 0;
    @include MQ-Below(medium) {
        scroll-margin-top: 68px;
    }

    &::before {
        content: '';
        position: absolute;
        background: url("/assets/img/bg_orange_pattern.png") center center repeat;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: .4;
        z-index: -1;
    }

    &__inner {
        @include Container(940px);
        background: #fff;
        padding: var(--spacing-s) var(--spacing-s) var(--spacing) var(--spacing-s);

        h2 {
            @extend .heading--2;
            @include MQ-Below(medium) {
                font-size: 1.375rem;
                font-weight: 500;
            }
        }

        h3 {
            font-size: 1.625rem;
            font-weight: 600;
            @include MQ-Below(medium) {
                font-size: 1.25rem;
            }
        }

        h2 + h3 {
            margin-top: .75rem;
        }

        .contact-form__date {
            display: block;
            font-size: 1.625rem;
            font-weight: 600;
            @include MQ-Below(medium) {
                font-size: 1.25rem;
            }
        }

        h3 + .contact-form__date {
            margin-top: 3rem;
        }
    }

    .message {
        &__error {
            background: var(--color__secondary);
            border-radius: 5px;
            color: white;
            font-size: .95rem;
            padding: 0.25rem 0.5rem;

            a {
                text-decoration: underline;
            }
        }

        &__notice {
            background: var(--color__secondary);
            border-radius: 5px;
            color: white;
            font-size: .95rem;
            padding: 0.25rem 0.5rem;

            a {
                text-decoration: underline;
            }
        }
    }
}
