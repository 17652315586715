:root {
    --mega-list__number-height: 80px;
    --mega-list__number-transform: translate3d(-50%, -50%, 0) scale(0.8);

    @include MQ-Above(medium) {
        --mega-list__number-transform: translate3d(-50%, -50%, 0);
    }
}

.mega-list {
    background: var(--color__light-orange);
    padding: var(--spacing-l) 0 0 0;

    header {
        margin-bottom: var(--spacing);

        h2 {
            @extend .heading--2;
            text-align: center;
            margin-bottom: var(--spacing);
            @include MQ-Below(medium) {
                font-weight: 700;
            }
        }

        p {
            @extend .text--regular;
        }
    }

    &__inner {
        @include Container(940px);
    }

    &__item {
        @include Grid(4fr 5fr);
        @include Gap();
        align-items: flex-start;
        @include MQ-Below(medium) {
            gap: 1rem;
            max-width: 90%;
            margin-left: auto;
        }
    }

    &__media {
        position: relative;

        &--with-number {
            margin-top: calc(var(--mega-list__number-height) / 2);

            &:before {
                content: "";
                position: absolute;
                width: var(--mega-list__number-height);
                height: var(--mega-list__number-height);
                top: 0rem;
                left: 0;
                transform: var(--mega-list__number-transform);
                transform-origin: bottom right;
                background: var(--color__bright-blue);
                border-radius: 50%;
                z-index: 0;
                @include MQ-Below(medium) {
                    width: 100px;
                    height: 100px;
                }
            }
        }

        &__icon {
            position: absolute;
            bottom: -60px;
            right: -60px;
            width: 60px;
            height: 60px;
            transform: var(--mega-list__number-transform);
            transform-origin: bottom left;
            z-index: 2;
        }

        &__image {
            position: relative;
            z-index: 1;
        }

        &__number {
            position: absolute;
            width: 80px;
            height: 80px;
            top: 0;
            left: 0;
            transform: var(--mega-list__number-transform);
            transform-origin: bottom right;
            border-radius: 50%;
            font-size: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            z-index: 2;
            font-weight: 600;
            @include MQ-Below(medium) {
                width: 100px;
                height: 100px;
                font-size: 4rem;
                font-weight: 700;
            }
        }
    }

    &__content {
        &--with-number {
            margin-top: calc(var(--mega-list__number-height) / 2);
            @include MQ-Below(medium) {
                margin-top: .5rem;
            }

            p {
                @include MQ-Below(medium) {
                    font-size: .875rem;
                }
            }

            .link--arrow {
                position: relative;
                max-width: calc(100% - 24px);
                &::after {
                    position: absolute;
                    bottom: 7px;
                    @include MQ-Below(medium) {
                        bottom: 3px;
                    }
                }
            }
        }

        h3 {
            @extend .heading--4;
            margin-bottom: var(--spacing-s);
            @include MQ-Below(medium) {
                font-weight: 500;
                font-size: 1.375rem;
            }
        }

        h4 {
            @extend .heading--5;
            font-weight: 700;
            margin-bottom: 0.375rem;
            @include MQ-Below(medium) {
                font-size: 1.125rem;
             }
        }

        ul {
            display: grid;
            gap: var(--spacing-s);
            @include MQ-Below(medium) {
                gap: var(--spacing);
             }
        }
    }

    &--firstItem {
        position: relative;
        overflow: hidden;

        &:before {
            content: "";
            width: 14vw;
            height: 6vw;
            background: url(/assets/img/squares.svg) no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            top: 3rem;
            left: 0;
            transform: translate3d(-5%, 40%, 0);
            @include MQ-Below(medium) {
                display: none;
            }
        }

        .mega-list__inner {
            header {
                position: relative;

                &:before {
                    @include MQ-Below(medium) {
                        display: block;
                        content: "";
                        position: absolute;
                        bottom: -3rem;
                        left: auto;
                        right: -3rem;
                        width: 116px;
                        height: 10vh;
                        background: url(/assets/img/squares.svg) no-repeat;
                        background-size: contain;
                        transform: translate3d(-5%, 40%, 0);
                    }
                }
            }
        }


    }

    &--lastItem {
        padding-bottom: var(--spacing-xl);
        position: relative;
        overflow: hidden;

        &:after {
            content: "";
            width: clamp(133px, 20vw, 335px);
            height: clamp(133px, 20vw, 335px);
            background: url(/assets/img/circles.svg) no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate3d(20%, 20%, 0);
        }
    }
}
