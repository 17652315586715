:root {
    @include MQ-Below(medium) {
        --icon-list__items__margin: 1.875rem 0 0 0;
    }

    @include MQ-Above(medium) {
        --icon-list__icon__max-height: 4rem;
        --icon-list__icon__margin-left: -1.4375rem;
    }
}

.icon-list {
    padding: var(--spacing) 0;
    position: relative;

    &__inner {
        @include Container();
        @include Columns(2);
        align-items: center;
        position: relative;
        @include MQ-Below(medium) {
            gap: 1rem;
        }
    }

    &__text {
        padding-left: 3rem;
        @include MQ-Below(medium) {
            padding-left: 0;
        }

        h2 {
            @extend .heading--2;
            margin-bottom: 2rem;
            @include MQ-Below(medium) {
                font-weight: 700;
                margin-bottom: .5rem
            }
        }

        p {
            @extend .text--regular;
        }
    }

    &__items {
        margin: var(--icon-list__items__margin);
        display: grid;
        grid-gap: 0.625rem;
        max-width: 23rem;
        justify-self: center;
        z-index: 2;
        @include MQ-Below(medium) {
            max-width: 19rem;
            margin-bottom: 2rem;
        }

        li {
            background: var(--color__bright-blue);
            color: white;
            display: flex;
            align-items: center;
            padding: 20px 10px 20px 0;

            img {
                // max-height: var(--icon-list__icon__max-height, 2rem);
                align-items: center;
                width: 60px;
                height: 66px;
                margin-left: var(--icon-list__icon__margin-left, -10px);
                margin-right: 20px;
                @include MQ-Below(medium) {
                    width: 40px;
                    height: 46px;
                    margin-left: var(--icon-list__icon__margin-left, -15px);
                }
            }

            p {
                display: flex;
                align-items: center;
            }
        }

        &__number {
            @extend .heading--1;
            display: block;
            margin-right: 20px;
            font-weight: 700;
        }
    }

    .accent--top-left {
        position: absolute;
        top: 5rem;
        left: 0;
        z-index: -1;
        @include MQ-Below(medium) {
            left: -2rem;
            top: 4rem;
        }
    }
    .accent--bottom-right {
        position: absolute;
        bottom: -3rem;
        right: 0;
        z-index: 1;
        @include MQ-Below(medium) {
            bottom: -0.65rem;
            svg {
                height: 100px;
                width: 100px;
            }
        }
    }
}

.image-with-quote--large + .icon-list {
    margin-top: -8.5rem;
    @include MQ-Below(large) {
        margin-top: -3rem;
    }
}

.image-with-quote--no-quote + .icon-list {
    margin-top: 0;
}
