.t__board{
    h2 {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 3rem;
        margin: 0;
        @include MQ-Below(medium) {
            font-size: 1.75rem;
            line-height: 2.125rem;
            font-weight: 700;
            max-width: 85%;
        }
    }
}

.board-meetings__container {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 2rem;
    max-width: 1350px;
    margin: 0 auto;
    width: calc(100% - 3.75rem);
    @include MQ-Below(medium) {
        display: block;
    }
}
.board-meetings {
    padding: var(--spacing) 0;
    margin: 0rem 0;
}
.board-meetings__welcome {
    font-weight: 600;
    line-height: 48px;
}
.board-meetings aside {
    grid-column: span 4 / span 4;
    padding-right: 2rem;
    @include MQ-Below(medium) {
        padding-right: 0;
    }
}
.board-meetings__col2 {
    grid-column: span 8 / span 8;
    @include MQ-Below(medium) {
        margin-top: 2rem;
    }
}
.board-meetings p {
    font-size: inherit;
}
.section-heading {
    font-size: 40px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-bottom: 0rem;
    @include MQ-Below(medium) {
        font-size: 28px;
        font-weight: 700;
    }
}
.section-heading__icon {
    margin-right: 0.5rem;
}
.meeting {
    margin-top: 0;
    margin-left: 2rem;
}

// ol.meeting {
//     list-style-type: decimal !important;
// }
ol.meeting ol.meeting__list {
    padding: 5px 0;
    list-style: lower-alpha;
}
ol.meeting li{
    display: list-item;
    list-style-type: decimal;
}
.meeting__heading {
    font-size: 18px;
}
ol.meeting ol.meeting__list li.meeting__item {
    font-size: 18px;
    margin-left: 25px;
    padding: 5px 0;
    list-style: lower-alpha;
}
.meeting__button {
    margin: 1rem 0;
}
.meeting__buttoninfo {
    display: block;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.5px;
    margin-top: 5px;
}
.meeting__title {
    font-size: 30px;
    font-weight: 500;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 3px solid var(--color__bright-yellow);
    @include MQ-Below(medium) {
        font-size: 24px;
    }
}
.meeting__link {
    text-decoration: underline;
    color: var(--color__blue);
}
form.download-documents{
    margin: 0.5rem 0;
}