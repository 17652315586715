.events-listing {
    padding: var(--spacing) 0;

    h2 {
        @extend .heading--2;
        font-weight: 600;
        margin-bottom: 3.75rem;
        @include MQ-Below(medium) {
            margin-bottom: 1.25rem;
        }
    }

    h3 {
        @extend .heading--4;
        display: inline;
        margin-bottom: 0.375rem;
    }

    hr {
        margin: 2.875rem 0;
        height: 3px;
        width: 100%;
        background: url(/assets/img/dot.svg) repeat;
        border: none;
        @include MQ-Below(medium) {
            margin: 1.875rem 0;
        }

        &:last-of-type {
            display: none;
        }
    }

    p {
        @include MQ-Below(medium) {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
    }

    .link--arrow {
        position: relative;
        max-width: calc(100% - 24px);
        &:hover {
            text-decoration: none
        }

        &::after {
            position: absolute;
            bottom: 7px;
            @include MQ-Above(medium) {
                bottom: 6px;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-left: 11px solid currentColor;
            }
        }

        h3 {
            &:hover {
                text-decoration: underline
            }
        }
    }

    &__inner {
        @extend .container;
    }

    .events {
        .event {
            display: flex;
            flex-direction: row;
            // gap: 3.75rem;
            @include MQ-Below(medium) {
                flex-direction: column-reverse;
                // gap: 1.25rem;
            }

            &__content {
                flex: 1;
                margin-right: 3.75rem;
                @include MQ-Below(medium) {
                    margin-right: 0;
                    margin-top: 1.25rem;
                }
            }

            &__date {
                font-size: 1.375rem;
                font-weight: 500;
                line-height: 1.75rem;
                margin-bottom: 0.375rem;
                @include MQ-Below(medium) {
                    font-size: 1rem;
                    line-height: 1.25rem;
                }
            }

            &__subtitle {
                font-size: 1.375rem;
                font-weight: 500;
                line-height: 1.75rem;
                margin-bottom: 0.375rem;
                @include MQ-Below(medium) {
                    font-size: 1rem;
                    line-height: 1.25rem;
                }
            }

            &__image {
                max-width: 360px;
                @include MQ-Below(medium) {
                    max-width: 100%;
                }
            }
        }
    }

    .event__subtitle + p {
        margin-top: 1.25rem;
    }
}

hr.simple {
    border: none;
    border-bottom: 1px solid #177ABC;
    margin: 0;
    @include MQ-Below(medium) {
        margin: 0.625rem 0;
    }
}
