:root {
    --heading-font-family: var(--font-primary);
}

.heading {
    &--1 {
        font-family: var(--heading-font-family);
        font-size: 2.125rem;
        line-height: 2.6875rem;
        font-weight: 800;

        @include MQ-Above(medium) {
            font-size: 2.475rem;
            line-height: 3.05rem;
            font-weight: 700;
        }

        @include MQ-Above(large) {
            font-size: 2.875rem;
            line-height: 3.25rem;
        }
    }

    &--2 {
        font-family: var(--heading-font-family);
        font-size: 1.75rem;
        line-height: 2.125rem;
        font-weight: 600;

        @include MQ-Above(medium) {
            font-size: 2.25rem;
            line-height: 2.5rem;
        }

        @include MQ-Above(large) {
            font-size: 2.5rem;
            line-height: 3rem;
        }
    }

    &--3 {
        font-family: var(--heading-font-family);
        font-size: 1.375rem;
        line-height: 1.625rem;
        font-weight: 500;

        @include MQ-Above(medium) {
            font-size: 1.625rem;
            line-height: 2rem;
        }

        @include MQ-Above(large) {
            font-size: 1.875rem;
            line-height: 2.375rem;
        }
    }

    &--4 {
        font-family: var(--heading-font-family);
        font-size: 1.25rem;
        line-height: 1.625rem;
        font-weight: 600;

        @include MQ-Above(medium) {
            font-size: 1.425rem;
            line-height: 1.75rem;
        }

        @include MQ-Above(large) {
            font-size: 1.625rem;
            line-height: 1.875rem;
        }
    }

    &--5 {
        font-family: var(--heading-font-family);
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;

        @include MQ-Above(medium) {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }

        @include MQ-Above(large) {
            font-size: 1.375rem;
            line-height: 1.75rem;
        }
    }

    &--6 {
        font-family: var(--heading-font-family);
        font-size: 0.8125rem;
        line-height: 1.125rem;
        font-weight: 400;

        @include MQ-Above(medium) {
            font-size: 0.845rem;
            line-height: 1.175rem;
        }

        @include MQ-Above(large) {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
    }
}
